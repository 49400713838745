import { HaxCompressor } from "./HaxCompressor";
import { HaxRNG } from "./HaxRNG";

declare let process: any;

export class Utils {

	/** Generate a random integer between min and max(inclusive) */
	public static randomInt(min: number, max: number) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}

	/** Generate a random integer between min and max(exclusive) */
	public static rangeInt(min: number, max: number): number {
		min = (min) | 0;
		max = (max) | 0;
		return ((Math.random() * (max - min)) + min) | 0;
	}

	/** Generate a random float between min and max(exclusive) */
	public static rangeFloat(min: number, max: number): number {
		min = +(min);
		max = +(max);
		return +((Math.random() * (max - min)) + min);
	}

	public static pickArray<T>(array: T[]): T | undefined {
		if (array.length <= 0) {
			return undefined;
		}
		return array[Utils.rangeInt(0, array.length)];
	}

	public static minMax(value: number, min = 0, max = 1) {
		if (min >= max) { throw new Error("[minMax] min >= max"); }
		return (value > max) ? max : ((value < min) ? min : value);
	}

	/** Converts number from one range to another */
	public static remapRange(value: number, sourceMin = 0, sourceMax = 1, targetMin = 0, targetMax = 1) {
		if (sourceMin >= sourceMax) { throw new Error("[remapRange] sourceMin >= sourceMax"); }
		if (value > sourceMax) { value = sourceMax; }
		if (value < sourceMin) { value = sourceMin; }

		return (value - sourceMin) * (targetMax - targetMin) / (sourceMax - sourceMin) + targetMin;
	}

	public static sortedIndex(array: number[], value: number) {
		let low = 0;
		let high = array.length;

		while (low < high) {
			let mid = low + high >>> 1;
			if (array[mid] < value) {
				low = mid + 1;
			} else {
				high = mid;
			}
		}
		return low;
	}

	public static sortedInsert(array: number[], value: number) {
		let index = Utils.sortedIndex(array, value);
		array.splice(index, 0, value);
	}

	public static sortedIndexComparator<T>(array: T[], value: T, comparator: (a: T, b: T) => number) {
		let min = 0;
		let max = array.length;
		let index = (min + max) >>> 1;
		while (max > min) {
			if (comparator(value, array[index]) < 0) { max = index; } else { min = index + 1; }
			index = (min + max) >>> 1;
		}
		return index;
	}

	public static sortedInsertComparator<T>(array: T[], value: T, comparator: (a: T, b: T) => number) {
		let index = Utils.sortedIndexComparator(array, value, comparator);
		array.splice(index, 0, value);
	}

	public static async timeout(delay: number): Promise<number> {
		const lastTime = Date.now();
		return new Promise<number>(resolve => {
			setTimeout((args, ms) => {
				resolve(Date.now() - lastTime);
			}, delay);
		});
	}

	public static async immediate<T>(value?: T): Promise<T> {
		return new Promise<T>(resolve => {
			setImmediate(() => resolve(value as T));
		});
	}

	public static async nextTick<T>(value?: T): Promise<T> {
		return new Promise<T>(resolve => {
			if (process !== undefined) {
				process.nextTick(() => resolve(value as T));
			} else {
				setImmediate(() => resolve(value as T));
			}
		});
	}

	public static strReplaceMulti(str: string, replace: { [K: string]: string }) {
		let out = "";

		let i = 0;
		while (i < str.length) {
			let replaced = false;
			for (const find in replace) {
				if (replace.hasOwnProperty(find)) {
					const target = replace[find];
					if (str.startsWith(find, i)) {
						out += target;
						i += find.length;
						replaced = true;
						break;
					}
				}
			}
			if (!replaced) {
				out += str[i];
				i++;
			}
		}
		return out;
	}

	private static hidIndex = 0;
	public static generateHID() {
		const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let text = "";
		const time = new Date().valueOf();
		for (let i = 0; i < 8; i++) {
			text += chars.charAt(Math.floor(Math.random() * chars.length));
		}
		for (let i = 0; i < 2; i++) {
			text += chars.charAt(Math.floor(i > 0 ? Utils.hidIndex / Math.pow(chars.length, i) : Utils.hidIndex) % chars.length);
		}
		Utils.hidIndex++;
		for (let i = 0; i < 6; i++) {
			text += chars.charAt(Math.floor(i > 0 ? time / Math.pow(chars.length, i) : time) % chars.length);
		}
		return text;
	}

	private static huuidIndex = 0;
	private static huuidRNG = new HaxRNG(Date.now());
	public static generateHUUID() {
		const time = (Date.now() / 1000) | 0;
		let text = HaxCompressor.compressNumber(time, 6);

		text += HaxCompressor.compressNumber(Utils.huuidIndex, 3);
		for (let i = 0; i < 4; i++) {
			text += HaxCompressor.chars.charAt(Math.floor(Math.random() * HaxCompressor.charsLength));
		}
		for (let i = 0; i < 3; i++) {
			text += Utils.huuidRNG.nextHChar();
		}
		Utils.huuidIndex++;
		return text;
	}

	public static generateHUUID24() {
		const time = (Date.now() / 1000) | 0;
		let text = HaxCompressor.compressNumber(time, 8);

		text += HaxCompressor.compressNumber(Utils.huuidIndex, 3);
		for (let i = 0; i < 6; i++) {
			text += HaxCompressor.chars.charAt(Math.floor(Math.random() * HaxCompressor.charsLength));
		}
		for (let i = 0; i < 7; i++) {
			text += Utils.huuidRNG.nextHChar();
		}
		Utils.huuidIndex++;
		return text;
	}

	public static equals(a: any, b: any, keyIgnore?: (key: string) => boolean) {
		if (a === b) {
			return true;
		}

		if (Array.isArray(a) && Array.isArray(b)) {
			if (a.length !== b.length) { return false; }

			for (let i = 0; i < a.length; i++) {
				if (!Utils.equals(a[i], b[i], keyIgnore)) { return false; }
			}

			return true;
		}

		if (!(typeof (a) === "object") || !(typeof (b) === "object")) {
			return false;
		}

		if (a.constructor !== b.constructor) { return false; }

		for (const key in a) {
			if ((keyIgnore && keyIgnore(key))) { continue; }
			if (!a.hasOwnProperty(key)) { continue; }
			if (!b.hasOwnProperty(key)) { return false; }
			if (a[key] === b[key]) { continue; }
			if (typeof (a[key]) !== "object") { return false; }
			if (!Utils.equals(a[key], b[key], keyIgnore)) { return false; }
		}

		for (const key in b) {
			if ((keyIgnore && keyIgnore(key))) { continue; }
			if (b.hasOwnProperty(key) && !a.hasOwnProperty(key)) { return false; }
		}
		return true;
	}

	public static randomWeightedObject<T extends { weight: number }>(object: { [K: string]: T }) {
		let totalWeight = 0;
		for (let name in object) {
			if (object.hasOwnProperty(name)) {
				let element = object[name];
				totalWeight += element.weight;
			}
		}

		let randomWeight = Math.random() * totalWeight;
		for (let name in object) {
			if (object.hasOwnProperty(name)) {
				let element = object[name];
				randomWeight -= element.weight;
				if (randomWeight <= 0) {
					return { key: name, value: element };
				}
			}
		}
		return undefined;
	}

	public static randomWeightedArray<T extends { weight: number }>(object: T[]) {
		let totalWeight = 0;
		for (let i = 0; i < object.length; i++) {
			const element = object[i];
			totalWeight += element.weight;
		}

		let randomWeight = Math.random() * totalWeight;
		for (let i = 0; i < object.length; i++) {
			const element = object[i];
			randomWeight -= element.weight;
			if (randomWeight <= 0) {
				return { key: i, value: element };
			}
		}
		return undefined;
	}

	public static getOrdinal(num: number) {
		let s = ["th", "st", "nd", "rd"];
		let v = num % 100;
		return num.toLocaleString("en-US") + (s[(v - 20) % 10] || s[v] || s[0]);
	}

	public static initArray<T>(size: number, value: ((i: number) => T) | T) {
		if (typeof value === "function") {
			const arr = [];
			arr.length = size;
			for (let i = 0; i < size; i++) {
				arr[i] = (value as ((i: number) => T))(i);
			}
			return arr;
		} else {
			const arr = [];
			arr.length = size;
			for (let i = 0; i < size; i++) {
				arr[i] = value;
			}
			return arr;
		}
	}

	public static initArray2<T>(sizeX: number, sizeY: number, value: ((x: number, y: number, i: number) => T)) {
		let size = sizeX * sizeY;
		const arr = [];
		arr.length = size;
		for (let y = 0; y < sizeY; y++) {
			for (let x = 0; x < sizeX; x++) {
				let i = y * sizeX + x;
				arr[i] = (value as ((x: number, y: number, i: number) => T))(x, y, i);
			}
		}
		return arr;
	}

	public static initArray3<T>(sizeX: number, sizeY: number, sizeZ: number, value: ((x: number, y: number, z: number, i: number) => T)) {
		let size = sizeX * sizeY * sizeZ;
		let sizeXY = sizeX * sizeY;
		const arr = [];
		arr.length = size;
		for (let z = 0; z < sizeZ; z++) {
			for (let y = 0; y < sizeY; y++) {
				for (let x = 0; x < sizeX; x++) {
					let i = z * sizeXY + y * sizeX + x;
					arr[i] = (value as ((x: number, y: number, z: number, i: number) => T))(x, y, z, i);
				}
			}
		}
		return arr;
	}

	public static normalizeNulls(data: any, inArray = false) {
		if (typeof (data) === "object") {
			// tslint:disable-next-line:forin
			for (const key in data) {
				let value = data[key];
				if (!inArray && value === null) {
					data[key] = undefined;
				} else if (typeof (value) === "object") {
					Utils.normalizeNulls(value, Array.isArray(value));
				}
			}
		}
	}
}
