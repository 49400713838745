import { HaxRNG } from "./HaxRNG";


// 41, 43, 47, 53, 59, 61, 67, 71, 73, 79, 83, 89, 97
const keySizeA = 47;
const keySizeB = 61;
const keySizeC = 83;

export class HaxEncryptor3 {

	private encA = new Uint8Array(256 * 256);
	private decA = new Uint8Array(256 * 256);

	private encB = new Uint8Array(256 * 256);
	private decB = new Uint8Array(256 * 256);

	private encC = new Uint8Array(256 * 256);
	private decC = new Uint8Array(256 * 256);

	private keyA: Uint8Array;
	private keyB: Uint8Array;
	private keyC: Uint8Array;

	public readonly keyHash: number;

	constructor(keyA: string, keyB: string, keyC: string) {
		this.randomize(this.encA, this.decA, HaxEncryptor3.stringHash(keyA));
		this.randomize(this.encB, this.decB, HaxEncryptor3.stringHash(keyB));
		this.randomize(this.encC, this.decC, HaxEncryptor3.stringHash(keyC));

		this.keyA = this.stringHashArray(keyA, keySizeA);
		this.keyB = this.stringHashArray(keyB, keySizeB);
		this.keyC = this.stringHashArray(keyC, keySizeC);

		this.keyHash = HaxEncryptor3.calculateHash(keyA, keyB, keyC);
	}

	public static calculateHash(keyA: string, keyB: string, keyC: string) {
		let keyHash = HaxEncryptor3.stringHash(keyA + keyB + keyC);
		keyHash = HaxEncryptor3.stringHash("Special0CaloriesSalt" + keyHash);
		keyHash = HaxEncryptor3.stringHash("EmptyCalories" + keyHash);
		keyHash = HaxEncryptor3.stringHash("ImpossibleSalt" + keyHash);
		return keyHash;
	}

	private randomize(encArray: Uint8Array, decArray: Uint8Array, seed: number) {
		const rngA = new HaxRNG(seed | 0);
		const rngB = new HaxRNG((seed + rngA.safeUInt() + 666) | 0);
		const rngC = new HaxRNG((seed + rngA.safeUInt() + 1337) | 0);

		for (let i = 0; i < 256; i++) {
			for (let j = 0; j < 256; j++) {
				encArray[(i * 256) + j] = j;
			}
			for (let j = 0; j < 256; j++) {
				const r2 = rngA.nextUByte(), tmp = encArray[(i * 256) + j];
				encArray[(i * 256) + j] = encArray[(i * 256) + r2];
				encArray[(i * 256) + r2] = tmp;
			}
			for (let j = 0; j < 256; j++) {
				const r1 = rngB.nextUByte(), tmp = encArray[(i * 256) + r1];
				encArray[(i * 256) + r1] = encArray[(i * 256) + j];
				encArray[(i * 256) + j] = tmp;
			}
			for (let j = 0; j < (4 * 256); j++) {
				const r1 = rngC.nextUByte(), r2 = rngC.nextUByte(), tmp = encArray[(i * 256) + r1];
				encArray[(i * 256) + r1] = encArray[(i * 256) + r2];
				encArray[(i * 256) + r2] = tmp;
			}
			for (let j = 0; j < 256; j++) {
				const r1 = rngA.nextUByte(), r2 = rngB.nextUByte(), tmp = encArray[(i * 256) + r1];
				encArray[(i * 256) + r1] = encArray[(i * 256) + r2];
				encArray[(i * 256) + r2] = tmp;
			}
		}

		for (let i = 0; i < 256; i++) {
			for (let j = 0; j < 256; j++) {
				decArray[(i * 256) + (encArray[(i * 256) + j])] = j;
			}
		}
	}

	public stringHashArray(str: string, size: number) {
		str = str + "$HaxEncryptor$" + str + "$HaxEncryptor$" + str;
		const length = str.length;

		const outBuffer = new Uint8Array(size);

		const inc = ((length / size) | 0);

		// outBuffer
		let hashCode = 0;
		for (let i = 0; i < size; i++) {
			const start = i * inc;
			hashCode += i;
			for (let j = i; j < i + 8; j++) {
				hashCode = (31 * hashCode + str.charCodeAt(j % length)) << 0;
			}
			outBuffer[i] = (hashCode | 0);
		}

		return outBuffer;
	}

	public static stringHash(str: string) {
		str = str + "$HaxEncryptor$" + str + "$HaxEncryptor$" + str;
		let hashCode = 0;
		for (let i = 0; i < str.length; i++) {
			hashCode = (31 * hashCode + str.charCodeAt(i)) << 0;
		}
		return hashCode;
	}

	public encrypt(buffer: Uint8Array, keyOffset: number) {
		const size = buffer.byteLength;
		const outBuffer = new Uint8Array(buffer.byteLength);
		for (let i = 0; i < size; i++) {
			outBuffer[i] = this.encryptByte(buffer[i], keyOffset + i);
		}
		return outBuffer;
	}

	public encryptByte(input: number, keyOffset: number) {
		input = input | 0;
		input = (this.encA[this.keyA[keyOffset % keySizeA] * 256 + input] | 0);
		input = (this.encB[this.keyB[keyOffset % keySizeB] * 256 + input] | 0);
		input = (this.encC[this.keyC[keyOffset % keySizeC] * 256 + input] | 0);
		return input;
	}

	public decrypt(buffer: Uint8Array, keyOffset: number) {
		const size = buffer.byteLength;
		const outBuffer = new Uint8Array(buffer.byteLength);
		for (let i = 0; i < size; i++) {
			outBuffer[i] = this.decryptByte(buffer[i], keyOffset + i);
		}
		return outBuffer;
	}

	public decryptByte(input: number, keyOffset: number) {
		input = input | 0;
		input = (this.decC[this.keyC[keyOffset % keySizeC] * 256 + input] | 0);
		input = (this.decB[this.keyB[keyOffset % keySizeB] * 256 + input] | 0);
		input = (this.decA[this.keyA[keyOffset % keySizeA] * 256 + input] | 0);
		return input;
	}
}
