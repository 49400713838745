import "./FormulatorWebsite.scss";

import { BasicComponent } from "haxt";

import { ScriptUtils } from "formulator-shared/Code/ScriptUtils";

export class FormulatorWebsite extends BasicComponent {

	public render() {
		return (
			<div class="FormulatorWebsite">
				FormulatorWebsite
				<div>
					{ScriptUtils.normalizeName("aaa")}
				</div>
			</div>
		);
	}
}

