import { h } from "../../../TSX/TSX";
import { BasicComponent } from "../../BasicComponent";
import { BasicEvent, Utils } from "@h4x/common";
import { NumberType, CEUtils } from "../CEUtils";
import { Validator, ValidatorOutput } from "../CEValidator";

export class NumberContainer extends BasicComponent<{
	schema: NumberType;
	data: any;
	key: any;
	onUpdate: BasicEvent<any>;
	value?: undefined;
	onInput?: undefined;
}> {
	public override init() {
		this.validate();
	}

	public override willReceiveProps() {
		this.update();
	}

	public get(): number {
		return this.props.data[this.props.key];
	}

	public set(value: number) {
		this.props.data[this.props.key] = value;
	}

	@BasicComponent.bind()
	private onInput(event: any) {
		let value = event.target.value;
		if (value.length <= 0) {
			value = undefined;
		} else {
			value = +value;
		}
		this.set(value);
		this.props.onUpdate.execute(value);
		this.validate();
	}

	private validatorData: ValidatorOutput;

	@BasicComponent.bind()
	private validate() {
		let oldData = this.validatorData;
		this.validatorData = Validator.number(this.props.schema, this.get(), this.props.data, this.props.key);

		if (Utils.equals(oldData, this.validatorData) === false) {
			console.log("validate...");
			this.update();
		}
	}

	public render() {
		let extra = CEUtils.parseInfo(this.validatorData);

		return <div style={{ "display": "inline-block" }}>
			<input class="InputContainer NumberContainer" type="number" value={this.get()} onInput={this.onInput} onBlur={this.validate} />
			{extra}
		</div>;
	}
}