import { BinaryUtils } from "../utils/BinaryUtils";

export const internalBuffer = new ArrayBuffer(8);
export const internalBytes = new Uint8Array(internalBuffer);
export const internalFloatArray = new Float32Array(internalBuffer);
export const internalDoubleArray = new Float64Array(internalBuffer);

export enum StringMode {
	Fixed8, Fixed16, Fixed24, Fixed32, Dynamic
}

export class InternalBuffer {
	public _buffer: ArrayBuffer;
	public buffer: Uint8Array;
	public bufferS: Int8Array;
	public _readPos: number = 0;
	public _writePos: number = 0;
	public _limit: number;

	constructor(size: number) {
		this._buffer = new ArrayBuffer(size);
		this.buffer = new Uint8Array(this._buffer);
		this.bufferS = new Int8Array(this._buffer);

		this._limit = this.buffer.byteLength;
	}

	public toBuffer() {
		return this.buffer.slice(0, this._writePos);
	}
}

export interface RawInternalBuffer {
	_buffer: ArrayBuffer;
	buffer: Uint8Array;
	bufferS: Int8Array;
	_readPos: number;
	_writePos: number;
	_limit: number;
}

export abstract class AbstractBuffer {

	/* Utils */
	public static isFloat32(value: number) {
		internalFloatArray[0] = +value;
		return value === internalFloatArray[0];
	}

	public abstract writeBool(value: boolean): void;
	public abstract readBool(): boolean;

	public abstract writeInt8(value: number): void;
	public abstract readInt8(): number;
	public abstract writeUInt8(value: number): void;
	public abstract readUInt8(): number;

	public abstract writeInt16(value: number): void;
	public abstract readInt16(): number;
	public abstract writeUInt16(value: number): void;
	public abstract readUInt16(): number;

	public abstract writeInt24(value: number): void;
	public abstract readInt24(): number;
	public abstract writeUInt24(value: number): void;
	public abstract readUInt24(): number;

	public abstract writeInt32(value: number): void;
	public abstract readInt32(): number;
	public abstract writeUInt32(value: number): void;
	public abstract readUInt32(): number;

	public abstract writeFloat32(value: number): void;
	public abstract readFloat32(): number;
	public abstract writeFloat64(value: number): void;
	public abstract readFloat64(): number;

	public abstract writeBuffer(buffer: Uint8Array): void;
	public abstract readBuffer(size: number): Uint8Array;

	public writeUByte(value: number) {
		this.writeUInt8(value);
	}

	public readUByte(): number {
		return this.readUInt8();
	}

	public writeByte(value: number) {
		this.writeInt8(value);
	}

	public readByte(): number {
		return this.readInt8();
	}

	public abstract getInternalBuffers(): InternalBuffer[];

	public readString(): string {
		const length = this.readUInt32();
		let buf = this.readBuffer(length);
		return this.stringFromBuffer(buf);
	}

	public writeString(string: string) {
		let buf = this.bufferFromString(string);
		this.writeUInt32(buf.byteLength);
		this.writeBuffer(buf);
	}

	public readVarInt() {
		let value = 0;
		let digits = 0;
		while (value <= 0) {
			let next = this.readInt8();
			if (next < 0) { value = (next << digits) + value; } else { value = (next << digits) - value; }
			digits += 7;
		}
		return value;
	}

	public writeVarInt(value: number) {
		while (value >= 128) { this.writeInt8(-(value & 0x7F)); value >>>= 7; }
	}

	public readStringMode(mode: StringMode): string {
		if (mode === StringMode.Fixed8) {
			const length = this.readUInt8();
			let buf = this.readBuffer(length);
			return this.stringFromBuffer(buf);
		} else if (mode === StringMode.Fixed16) {
			const length = this.readUInt16();
			let buf = this.readBuffer(length);
			return this.stringFromBuffer(buf);
		} else if (mode === StringMode.Fixed24) {
			const length = this.readUInt24();
			let buf = this.readBuffer(length);
			return this.stringFromBuffer(buf);
		} else if (mode === StringMode.Fixed32) {
			const length = this.readUInt32();
			let buf = this.readBuffer(length);
			return this.stringFromBuffer(buf);
		} else if (mode === StringMode.Dynamic) {
			let length = 0;
			let digits = 0;
			while (length <= 0) {
				let next = this.readInt8();
				if (next < 0) { length = (next << digits) + length; } else { length = (next << digits) - length; }
				digits += 7;
			}
			let buf = this.readBuffer(length);
			return this.stringFromBuffer(buf);
		} else {
			throw new Error("Invalid StringMode!");
		}
	}

	public writeStringMode(mode: StringMode, string: string) {
		if (mode === StringMode.Fixed8) {
			let buf = this.bufferFromString(string);
			this.writeUInt8(buf.byteLength);
			this.writeBuffer(buf);
		} else if (mode === StringMode.Fixed16) {
			let buf = this.bufferFromString(string);
			this.writeUInt16(buf.byteLength);
			this.writeBuffer(buf);
		} else if (mode === StringMode.Fixed24) {
			let buf = this.bufferFromString(string);
			this.writeUInt24(buf.byteLength);
			this.writeBuffer(buf);
		} else if (mode === StringMode.Fixed32) {
			let buf = this.bufferFromString(string);
			this.writeUInt32(buf.byteLength);
			this.writeBuffer(buf);
		} else if (mode === StringMode.Dynamic) {
			let buf = this.bufferFromString(string);
			let length = buf.byteLength;
			while (length >= 128) { this.writeInt8(-(length & 0x7F)); length >>>= 7; }
			this.writeInt8(length);
			this.writeBuffer(buf);
		} else {
			throw new Error("Invalid StringMode!");
		}
	}

	public bufferFromString(string: string) {
		// browser optimization
		if ((global as any).TextEncoder !== undefined) {
			let encoder = new TextEncoder();
			return encoder.encode(string);
		}
		return BinaryUtils.stringToUint8Array(string);
	}

	public stringFromBuffer(buffer: Uint8Array) {
		// browser optimization
		if ((global as any).TextDecoder !== undefined) {
			let decoder = new TextDecoder("utf-8");
			return decoder.decode(buffer);
		}
		return BinaryUtils.uint8ToStringArray(buffer);
	}
}
