import { BufferError } from "./BufferError";
import { AbstractBuffer, internalFloatArray, internalBytes, internalDoubleArray, InternalBuffer, StringMode } from "./AbstractBuffer";


export class DynamicBuffer extends AbstractBuffer {

	public readonly buffers: InternalBuffer[];

	private wBuffer: InternalBuffer;

	private rBuffer: InternalBuffer;
	private readBufferIndex = 0;

	constructor(buffers: InternalBuffer[], private chunkSize: number = 64 * 1024) {
		super();
		if (chunkSize < 8) {
			throw new BufferError("Chunk size can't be smaller than 8!");
		}
		this.buffers = buffers;
		this.allocateNewBuffer();
		this.rBuffer = this.wBuffer;
	}

	public static mergeBuffers(...buffers: AbstractBuffer[]) {
		let buffs = [];
		for (const merge of buffers) {
			buffs.push(...(merge.getInternalBuffers()));
		}
		return new DynamicBuffer(buffs, 8);
	}

	public getInternalBuffers() {
		return this.buffers;
	}

	public static allocate(chunkSize: number = 64 * 1024) {
		return new DynamicBuffer([], chunkSize);
	}

	public restart() {
		(this as any).buffers = [];
		this.allocateNewBuffer();
		this.rBuffer = this.wBuffer;
		this.readBufferIndex = 0;
	}

	private allocateNewBuffer() {
		this.wBuffer = new InternalBuffer(this.chunkSize);
		this.buffers.push(this.wBuffer);
	}

	private nextReadBuffer() {
		this.rBuffer = this.buffers[++this.readBufferIndex];
		if (this.rBuffer === undefined) {
			throw new BufferError("Read out of bounds");
		}
	}

	public writeBool(value: boolean) {
		if (this.wBuffer._limit < (this.wBuffer._writePos + 1)) {
			this.allocateNewBuffer();
		}
		this.wBuffer.buffer[this.wBuffer._writePos++] = (value ? 1 : -1);
	}

	public readBool(): boolean {
		if (this.rBuffer._writePos < (this.rBuffer._readPos + 1)) {
			this.nextReadBuffer();
		}
		return (this.rBuffer.buffer[this.rBuffer._readPos++] === 1);
	}

	public writeUInt8(value: number) {
		if (this.wBuffer._limit < (this.wBuffer._writePos + 1)) {
			this.allocateNewBuffer();
		}
		this.wBuffer.buffer[this.wBuffer._writePos++] = (value | 0);
	}

	public readUInt8(): number {
		if (this.rBuffer._writePos < (this.rBuffer._readPos + 1)) {
			this.nextReadBuffer();
		}
		return this.rBuffer.buffer[this.rBuffer._readPos++];
	}

	public writeInt8(value: number) {
		if (this.wBuffer._limit < (this.wBuffer._writePos + 1)) {
			this.allocateNewBuffer();
		}
		this.wBuffer.bufferS[this.wBuffer._writePos++] = (value | 0);
	}

	public readInt8(): number {
		if (this.rBuffer._writePos < (this.rBuffer._readPos + 1)) {
			this.nextReadBuffer();
		}
		return this.rBuffer.bufferS[this.rBuffer._readPos++];
	}

	public writeInt16(value: number) {
		if (this.wBuffer._limit < (this.wBuffer._writePos + 2)) {
			this.allocateNewBuffer();
		}
		value = value | 0;
		this.wBuffer.bufferS[this.wBuffer._writePos + 0] = (value >> 8);
		this.wBuffer.buffer[this.wBuffer._writePos + 1] = value;
		this.wBuffer._writePos += 2;
	}

	public readInt16(): number {
		if (this.rBuffer._writePos < (this.rBuffer._readPos + 2)) {
			this.nextReadBuffer();
		}
		const out = (this.rBuffer.bufferS[this.rBuffer._readPos + 0] << 8) | this.rBuffer.buffer[this.rBuffer._readPos + 1];
		this.rBuffer._readPos += 2;
		return out;
	}

	public writeUInt16(value: number) {
		if (this.wBuffer._limit < (this.wBuffer._writePos + 2)) {
			this.allocateNewBuffer();
		}
		value = value | 0;
		this.wBuffer.buffer[this.wBuffer._writePos + 0] = (value >> 8);
		this.wBuffer.buffer[this.wBuffer._writePos + 1] = value;
		this.wBuffer._writePos += 2;
	}

	public readUInt16(): number {
		if (this.rBuffer._writePos < (this.rBuffer._readPos + 2)) {
			this.nextReadBuffer();
		}
		const out = (this.rBuffer.buffer[this.rBuffer._readPos + 0] << 8) | this.rBuffer.buffer[this.rBuffer._readPos + 1];
		this.rBuffer._readPos += 2;
		return out;
	}

	public writeInt24(value: number) {
		if (this.wBuffer._limit < (this.wBuffer._writePos + 3)) {
			this.allocateNewBuffer();
		}
		value = value | 0;
		this.wBuffer.bufferS[this.wBuffer._writePos + 0] = (value >> 16);
		this.wBuffer.buffer[this.wBuffer._writePos + 1] = (value >> 8);
		this.wBuffer.buffer[this.wBuffer._writePos + 2] = value;
		this.wBuffer._writePos += 3;
	}

	public readInt24(): number {
		if (this.rBuffer._writePos < (this.rBuffer._readPos + 3)) {
			this.nextReadBuffer();
		}
		const out = (this.rBuffer.bufferS[this.rBuffer._readPos + 0] << 16) |
			(this.rBuffer.buffer[this.rBuffer._readPos + 1] << 8) |
			this.rBuffer.buffer[this.rBuffer._readPos + 2];
		this.rBuffer._readPos += 3;
		return out;
	}

	public writeUInt24(value: number) {
		if (this.wBuffer._limit < (this.wBuffer._writePos + 3)) {
			this.allocateNewBuffer();
		}
		value = value >>> 0;
		this.wBuffer.buffer[this.wBuffer._writePos + 0] = (value >> 16);
		this.wBuffer.buffer[this.wBuffer._writePos + 1] = (value >> 8);
		this.wBuffer.buffer[this.wBuffer._writePos + 2] = value;
		this.wBuffer._writePos += 3;
	}

	public readUInt24(): number {
		if (this.rBuffer._writePos < (this.rBuffer._readPos + 3)) {
			this.nextReadBuffer();
		}
		const out = (this.rBuffer.buffer[this.rBuffer._readPos + 0] << 16) |
			(this.rBuffer.buffer[this.rBuffer._readPos + 1] << 8) |
			this.rBuffer.buffer[this.rBuffer._readPos + 2];
		this.rBuffer._readPos += 3;
		return out >>> 0;
	}

	public writeInt32(value: number) {
		if (this.wBuffer._limit < (this.wBuffer._writePos + 4)) {
			this.allocateNewBuffer();
		}
		value = value | 0;
		this.wBuffer.buffer[this.wBuffer._writePos + 0] = (value >> 24);
		this.wBuffer.buffer[this.wBuffer._writePos + 1] = (value >> 16);
		this.wBuffer.buffer[this.wBuffer._writePos + 2] = (value >> 8);
		this.wBuffer.buffer[this.wBuffer._writePos + 3] = value;
		this.wBuffer._writePos += 4;
	}

	public readInt32(): number {
		if (this.rBuffer._writePos < (this.rBuffer._readPos + 4)) {
			this.nextReadBuffer();
		}
		const out = (this.rBuffer.buffer[this.rBuffer._readPos + 0] << 24) |
			(this.rBuffer.buffer[this.rBuffer._readPos + 1] << 16) |
			(this.rBuffer.buffer[this.rBuffer._readPos + 2] << 8) |
			this.rBuffer.buffer[this.rBuffer._readPos + 3];
		this.rBuffer._readPos += 4;
		return out;
	}

	public writeUInt32(value: number) {
		if (this.wBuffer._limit < (this.wBuffer._writePos + 4)) {
			this.allocateNewBuffer();
		}
		value = value >>> 0;
		this.wBuffer.buffer[this.wBuffer._writePos + 0] = (value >> 24);
		this.wBuffer.buffer[this.wBuffer._writePos + 1] = (value >> 16);
		this.wBuffer.buffer[this.wBuffer._writePos + 2] = (value >> 8);
		this.wBuffer.buffer[this.wBuffer._writePos + 3] = value;
		this.wBuffer._writePos += 4;
	}

	public readUInt32(): number {
		if (this.rBuffer._writePos < (this.rBuffer._readPos + 4)) {
			this.nextReadBuffer();
		}
		const out = (this.rBuffer.buffer[this.rBuffer._readPos + 0] << 24) |
			(this.rBuffer.buffer[this.rBuffer._readPos + 1] << 16) |
			(this.rBuffer.buffer[this.rBuffer._readPos + 2] << 8) |
			this.rBuffer.buffer[this.rBuffer._readPos + 3];
		this.rBuffer._readPos += 4;
		return out >>> 0;
	}

	public writeFloat32(value: number) {
		if (this.wBuffer._limit < (this.wBuffer._writePos + 4)) {
			this.allocateNewBuffer();
		}
		internalFloatArray[0] = +value;
		this.wBuffer.buffer[this.wBuffer._writePos + 0] = (internalBytes[0] | 0);
		this.wBuffer.buffer[this.wBuffer._writePos + 1] = (internalBytes[1] | 0);
		this.wBuffer.buffer[this.wBuffer._writePos + 2] = (internalBytes[2] | 0);
		this.wBuffer.buffer[this.wBuffer._writePos + 3] = (internalBytes[3] | 0);
		this.wBuffer._writePos += 4;
	}

	public readFloat32(): number {
		if (this.rBuffer._writePos < (this.rBuffer._readPos + 4)) {
			this.nextReadBuffer();
		}
		internalBytes[0] = this.rBuffer.buffer[this.rBuffer._readPos + 0];
		internalBytes[1] = this.rBuffer.buffer[this.rBuffer._readPos + 1];
		internalBytes[2] = this.rBuffer.buffer[this.rBuffer._readPos + 2];
		internalBytes[3] = this.rBuffer.buffer[this.rBuffer._readPos + 3];
		this.rBuffer._readPos += 4;
		return internalFloatArray[0];
	}

	public writeFloat64(value: number) {
		if (this.wBuffer._limit < (this.wBuffer._writePos + 8)) {
			this.allocateNewBuffer();
		}
		internalDoubleArray[0] = +value;
		this.wBuffer.buffer[this.wBuffer._writePos + 0] = (internalBytes[0] | 0);
		this.wBuffer.buffer[this.wBuffer._writePos + 1] = (internalBytes[1] | 0);
		this.wBuffer.buffer[this.wBuffer._writePos + 2] = (internalBytes[2] | 0);
		this.wBuffer.buffer[this.wBuffer._writePos + 3] = (internalBytes[3] | 0);
		this.wBuffer.buffer[this.wBuffer._writePos + 4] = (internalBytes[4] | 0);
		this.wBuffer.buffer[this.wBuffer._writePos + 5] = (internalBytes[5] | 0);
		this.wBuffer.buffer[this.wBuffer._writePos + 6] = (internalBytes[6] | 0);
		this.wBuffer.buffer[this.wBuffer._writePos + 7] = (internalBytes[7] | 0);
		this.wBuffer._writePos += 8;
	}

	public readFloat64(): number {
		if (this.rBuffer._writePos < (this.rBuffer._readPos + 8)) {
			this.nextReadBuffer();
		}
		internalBytes[0] = this.rBuffer.buffer[this.rBuffer._readPos + 0];
		internalBytes[1] = this.rBuffer.buffer[this.rBuffer._readPos + 1];
		internalBytes[2] = this.rBuffer.buffer[this.rBuffer._readPos + 2];
		internalBytes[3] = this.rBuffer.buffer[this.rBuffer._readPos + 3];
		internalBytes[4] = this.rBuffer.buffer[this.rBuffer._readPos + 4];
		internalBytes[5] = this.rBuffer.buffer[this.rBuffer._readPos + 5];
		internalBytes[6] = this.rBuffer.buffer[this.rBuffer._readPos + 6];
		internalBytes[7] = this.rBuffer.buffer[this.rBuffer._readPos + 7];
		this.rBuffer._readPos += 8;
		return internalDoubleArray[0];
	}

	public writeBuffer(buffer: Uint8Array) {
		/*if (this.wBuffer._limit < (this.wBuffer._writePos + 8)) {
			this.allocateNewBuffer();
		}*/
		let offset = 0;
		while (offset !== buffer.byteLength) {
			if (this.wBuffer._limit === this.wBuffer._writePos) { this.allocateNewBuffer(); }
			let available = this.wBuffer._limit - this.wBuffer._writePos;
			available = Math.min(available, buffer.byteLength - offset);
			this.wBuffer.buffer.set(buffer.subarray(offset, offset + available), this.wBuffer._writePos);
			this.wBuffer._writePos += available;
			offset += available;
		}
	}

	public readBuffer(size: number) {
		let buffer = new Uint8Array(size);
		let offset = 0;
		while (offset !== buffer.byteLength) {
			if (this.rBuffer._writePos === this.rBuffer._readPos) { this.nextReadBuffer(); }
			let available = this.rBuffer._writePos - this.rBuffer._readPos;
			available = Math.min(available, buffer.byteLength - offset);
			buffer.set(this.rBuffer.buffer.subarray(this.rBuffer._readPos, this.rBuffer._readPos + available), offset);
			this.rBuffer._readPos += available;
			offset += available;
		}
		return buffer;
	}

	public toBuffer() {
		let length = 0;

		for (const buffer of this.buffers) {
			length += buffer._writePos;
		}

		let out = new Uint8Array(length);
		let offset = 0;
		for (const buffer of this.buffers) {
			out.set(new Uint8Array(buffer._buffer, 0, buffer._writePos), offset);
			offset += buffer._writePos;
		}

		return out;
	}
}
