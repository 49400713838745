import { h } from "../../TSX/TSX";
import { BasicComponent } from "../BasicComponent";
// import * as Ajv from "ajv";
import { BasicEvent, Utils } from "@h4x/common";

import "./ConfigEditor.scss";
import { ObjectType, Type } from "./CEUtils";
import { RootContainer } from "./Containers/RootContainer";
import { InputTextBox } from "../FormComponents";

// let ajv = new Ajv({});
// ajv.addMetaSchema(require("ajv/lib/refs/json-schema-draft-06.json"));

/*let Input = (element: JSX.Element) => {
	return {
		render: () => <span>AAA {element}</span>
	};
}*/

/*
boolean
number (int, float) (min, max)
string
text (multiline string)
array
map (js's object)
object

*/

const objectCombined: ObjectType = {
	type: Type.Object,
	properties: {
		object: {
			type: Type.Object,
			properties: {
				enabled: { type: Type.Boolean },
				id: { type: Type.Number, min: 1, max: 10 },
				name: { type: Type.String },
				description: { type: Type.String, multiline: true }
			}
		},
		map: {
			type: Type.Map,
			elements: {
				type: Type.Object,
				properties: {
					enabled: { type: Type.Boolean },
					id: { type: Type.Number, min: 1, max: 10 },
					name: { type: Type.String },
					description: { type: Type.String, multiline: true }
				}
			}
		},
		array: {
			type: Type.Array,
			elements: {
				type: Type.Object,
				properties: {
					enabled: { type: Type.Boolean, default: true },
					id: { type: Type.Number, min: 1, max: 10 },
					name: { type: Type.String, default: "Default value" },
					description: { type: Type.String, multiline: true, optional: true }
				}
			}
		},
		stringsArray: {
			type: Type.Array,
			elements: {
				type: Type.String,
				options: ["A", "B", "C", "D", "E", "F"]
			}
		},
		stringsMap: {
			type: Type.Map,
			elements: { type: Type.String }
		}
	}
};

const testSchema = objectCombined;

export class ConfigEditor extends BasicComponent {

	@BasicComponent.var()
	public json: string;

	private onUpdate = new BasicEvent();
	private data = {
		object: { enabled: true },
		map: {
			a: { name: "a" }, b: { id: 1 }, c: { name: "c" }
		},
		array: [{ name: "0" }, { id: 1 }, { name: "2" }],
		stringsArray: ["A", "", "C"],
		stringsMap: { a: "A", b: "1", c: "C" }
	};

	@BasicComponent.initialize()
	private initLink() {
		this.json = JSON.stringify(this.data, null, "\t");
		this.onUpdate.addCallback(() => {
			this.json = JSON.stringify(this.data, null, "\t");
		});
	}

	/*public validate() {
		let valid = ajv.validate(JSON.parse(this.schema), JSON.parse(this.json));
		if (!valid) {
			this.message = JSON.stringify(ajv.errors);
		} else {
			this.message = "valid";
		}
	}*/


	public render() {
		return (
			<div class="JSONEditor">
				<div class="RootContainer" style={{ "flex": "1 0 0", margin: 10, overflow: "auto" }}>
					<RootContainer name={"Config"} schema={testSchema} data={this.data} onUpdate={this.onUpdate} style={{ "margin": "3px 0" }} />
				</div>
				<div class="JSONContainer">
					<InputTextBox $={this.$("json")} style={{ "flex": "1 0 0", margin: 10 }} />
				</div>
			</div>
			/*<div>
				Schema: <InputTextBox $={this.$("schema")} /><br />
				JSON: <InputTextBox $={this.$("json")} /><br />
				<BasicText $={this.$("message")} />
			</div>*/
			/*

					{renderContainer(testSchema, "root", this.data, this.onUpdate)}<br /><br />
			*/
		);
	}
}
