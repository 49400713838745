
class QueueItem<T> {
	private callback: () => Promise<T>;
	private promise: Promise<T>;

	constructor(callback: () => Promise<T>) {
		this.callback = callback;
		this.promise = new Promise((resolve, reject) => {
			this.resolve = resolve;
			this.reject = reject;
		});
	}

	public resolve(val: T) { }
	public reject(err: any) { }

	public execute(queue: AsyncQueue) {
		return this.callback().then((val) => {
			(<any>queue).onItemFinished(this);
			this.resolve(val);
		}, (err) => {
			(<any>queue).onItemError(this, err);
			this.reject(err);
		});
	}
}

export class AsyncQueue {
	private queue: QueueItem<any>[] = [];
	private slots: number = 1;
	private running: number = 0;
	private scheduled = false;

	private manualStart = false;
	private active: boolean;

	private finishing: boolean = false;
	private finished: boolean = false;

	private stopOnError: boolean = false;
	private waitOnError: boolean = false;

	private crashed: boolean = false;

	constructor(config?: {
		slots?: number;
		stopOnError?: boolean;
		waitOnError?: boolean;
	}) {
		if (config !== undefined) {
			if (config.slots !== undefined) {
				this.slots = config.slots | 0;
			}
			if (config.stopOnError === true) {
				this.stopOnError = true;
			}
			if (config.waitOnError === true) {
				this.waitOnError = true;
			}
		}

		this.active = !this.manualStart;
	}

	public add<T>(callback: () => Promise<T>): Promise<T> {
		if (this.finished) {
			throw new Error("Queue already finished!");
		}
		let item = new QueueItem(callback);
		this.queue.push(item);
		this.trySchedule();
		return (<any>item).promise;
	}

	private onItemFinished<T>(item: QueueItem<T>) {
		this.slots++;
		this.running--;
		if (this.crashed === true) {
			if (this.waitOnError) {
				if (this.running === 0) {
					if (this.finishing) {
						this._lastItemCallback();
						this.finished = true;
					}
				}
			}
		} else if (this.queue.length > 0) {
			this.trySchedule();
		} else if (this.running === 0) {
			this.lastItemCallback();
			if (this.finishing) {
				this._lastItemCallback();
				this.finished = true;
			}
		}
	}

	private onItemError<T>(item: QueueItem<T>, err: any) {
		this.slots++;
		this.running--;
		if (this.stopOnError) {
			this.queue = []; // nuke the queue if we are stopping on error
			if (this.waitOnError) {
				this.active = false;
				if (this.crashed === false) {
					this._lastItemCallback = () => {
						this.errorItemCallback(err);
						this._lastItemError(err);
					};
					this.crashed = true;
				}
				if (this.running === 0) {
					this._lastItemCallback();
				}
			} else {
				this.errorItemCallback(err);
				this.queue = [];
				this.running = 0;
				this.crashed = true;
				this.active = false;
				if (this.finishing && !this.waitOnError) {
					this._lastItemError(err);
					this.finished = true;
				}
			}
		} else {
			if (this.crashed === true) { return; }
			this.errorItemCallback(err);
			if (this.queue.length > 0) {
				this.trySchedule();
			} else if (this.running === 0) {
				this.lastItemCallback();
				if (this.finishing && this.waitOnError) {
					this._lastItemError(err);
				}
			}
			if (this.finishing && !this.waitOnError) {
				this._lastItemError(err);
				this.finished = true;
			}
		}
	}

	private trySchedule<T>() {
		if (this.active === true && this.scheduled === false && this.queue.length > 0 && this.slots > 0) {
			this.scheduled = true;
			process.nextTick(() => {
				this.run();
				this.scheduled = false;
			});
		}
	}

	private run() {
		while (this.queue.length > 0 && this.slots > 0) {
			let newItem = this.queue.shift();
			if (newItem !== undefined) {
				this.slots--;
				this.running++;
				(<QueueItem<any>>newItem).execute(this);
			}
		}
	}

	public lastItemCallback: () => void = () => { };
	private _lastItemCallback: () => void = () => { };

	public errorItemCallback: (err: any) => void = () => { };
	private _lastItemError: (err: any) => void = () => { };

	public finish() {
		this.finishing = true;
		if (this.running === 0 && this.queue.length === 0) {
			this.finished = true;
			return Promise.resolve();
		} else {
			return new Promise<void>((resolve, reject) => {
				this._lastItemCallback = resolve;
				this._lastItemError = reject;
			});
		}
	}

}

