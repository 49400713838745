import { NumberType, StringType, ElementType } from "./CEUtils";

export enum ValidatorType {
	Error,
	Warning,
	Info
}

export type ValidatorOutput = { errors: string[], warnings: string[], info: string[] };

export class Validator {

	public static boolean(schema: NumberType, value: boolean, container: any, key: string | number) {
		let data: ValidatorOutput = { errors: [], warnings: [], info: [] };
		Validator.required(data, schema, value);
		return data;
	}

	public static text(schema: StringType, value: string, container: any, key: string | number) {
		let data: ValidatorOutput = { errors: [], warnings: [], info: [] };
		Validator.required(data, schema, value);
		return data;
	}

	public static number(schema: NumberType, value: number, container: any, key: string | number) {
		let data: ValidatorOutput = { errors: [], warnings: [], info: [] };
		Validator.required(data, schema, value);
		Validator.numberRange(data, schema, value);
		return data;
	}

	private static numberRange(data: ValidatorOutput, schema: NumberType, value: number) {
		if (schema.min !== undefined && value < schema.min) {
			data.errors.push("The minimum value is " + schema.min);
		}
		if (schema.max !== undefined && value > schema.max) {
			data.errors.push("The maximum value is " + schema.max);
		}
	}

	private static required(data: ValidatorOutput, schema: ElementType, value: any) {
		if (schema.optional !== true && value === undefined) {
			data.warnings.push("This property must have a value!");
		}
	}
}
