
export class HaxCompressor {
	public static chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
	public static charsRev: { [K: string]: number } = {
		"0": 0, "1": 1, "2": 2, "3": 3, "4": 4, "5": 5, "6": 6, "7": 7, "8": 8, "9": 9, "A": 10, "B": 11, "C": 12, "D": 13,
		"E": 14, "F": 15, "G": 16, "H": 17, "I": 18, "J": 19, "K": 20, "L": 21, "M": 22, "N": 23, "O": 24, "P": 25, "Q": 26,
		"R": 27, "S": 28, "T": 29, "U": 30, "V": 31, "W": 32, "X": 33, "Y": 34, "Z": 35, "a": 36, "b": 37, "c": 38, "d": 39,
		"e": 40, "f": 41, "g": 42, "h": 43, "i": 44, "j": 45, "k": 46, "l": 47, "m": 48, "n": 49, "o": 50, "p": 51, "q": 52,
		"r": 53, "s": 54, "t": 55, "u": 56, "v": 57, "w": 58, "x": 59, "y": 60, "z": 61
	};

	public static charsLength = HaxCompressor.chars.length;

	public static compressNumber(num: number, size?: number) {
		if (num < 0 || num > Number.MAX_SAFE_INTEGER || num !== Math.floor(num)) {
			return ":" + num;
		}
		num = Math.floor(num);
		let text = "";
		let handled = 0;
		let i = 0;
		while (i < 9) {
			let charNumber = Math.floor(i > 0 ? num / Math.pow(HaxCompressor.charsLength, i) : num) % HaxCompressor.charsLength;
			text = HaxCompressor.chars.charAt(charNumber) + text;
			handled += (charNumber) * (Math.pow(HaxCompressor.charsLength, i));
			i++;
			if ((size === undefined || i >= size) && handled >= num) {
				break;
			}
		}
		return text;
	}


	public static decompressNumber(text: string) {
		if (text.startsWith(":")) {
			return +text.substring(1);
		}
		let num = 0;
		for (let i = 0; i < text.length; i++) {
			num *= HaxCompressor.charsLength;
			num += HaxCompressor.charsRev[text[i]];
		}
		return num;
	}

}
