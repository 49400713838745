import { h } from "../TSX/TSX";
import { BasicComponent } from "./BasicComponent";

import "./JSONView.scss";


function highlightJSON(value: any, tabs: number = 0, out: (JSX.Element | string)[] = []) {
	let type = typeof value;
	if (value === null) {
		out.push(<span class="null">null</span>);
	} else if (value === undefined) {
		out.push(<span class="undefined">undefined</span>);
	} else if (type === "number") {
		out.push(<span class="number">{+value}</span>);
	} else if (type === "string") {
		out.push(<span class="string">"{value}"</span>);
	} else if (type === "boolean") {
		out.push(<span class={"" + value}>{"" + value}</span>);
	} else if (value.constructor === Object) {
		out.push("{\n");
		tabs += 1;
		let first = true;
		for (const key in value) {
			if (value.hasOwnProperty(key)) {
				const element = value[key];
				out.push(first ? ("\t".repeat(tabs)) : (",\n" + "\t".repeat(tabs)));
				out.push("\"");
				out.push(<span class="key">{key}</span>);
				out.push("\": ");
				highlightJSON(element, tabs, out);
				first = false;
			}
		}
		tabs -= 1;
		out.push("\n" + "\t".repeat(tabs) + "}");
	} else if (value.constructor === Array) {
		out.push("[\n");
		tabs += 1;
		let isNumericArray = (value as any[]).every(x => typeof x === "number");
		if (isNumericArray) {
			for (let i = 0; i < value.length; i++) {
				const element = value[i];
				out.push(i !== 0 ? ", " : "\t".repeat(tabs));
				highlightJSON(element, tabs, out);
			}
		} else {
			for (let i = 0; i < value.length; i++) {
				const element = value[i];
				out.push(i !== 0 ? ",\n" + "\t".repeat(tabs) : "\t".repeat(tabs));
				highlightJSON(element, tabs, out);
			}
		}

		tabs -= 1;
		out.push("\n" + "\t".repeat(tabs) + "]");
	}
	return out;
}

export class JSONView extends BasicComponent<{ json: any }> {
	public override willReceiveProps() {
		this.update();
	}

	public render() {
		return (
			<pre class="json" style={{ flex: 1 }}>{highlightJSON(this.props.json)}</pre>
		);
	}
}
