export namespace ScriptUtils {
	export function normalizePath(path: string) {
		return path.replace(/([\/\\]+)/g, "/");
	}

	export function normalizeName(fileName: string) {
		fileName = normalizePath(fileName);
		if (fileName.endsWith(".js") || fileName.endsWith(".ts") || fileName.endsWith(".tsx")) {
			return fileName;
		}
		return fileName + ".js";
	}

	export function normalizeFilename(filename: string) {
		filename = normalizeName(filename);

		if (filename.indexOf("./") === 0) {
			filename = filename.substring(2);
		} else if (filename.indexOf("/") === 0) {
			filename = filename.substring(1);
		}
		return filename;
	}
}
