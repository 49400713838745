import { h } from "../../../TSX/TSX";
import { BasicComponent } from "../../BasicComponent";
import { BasicEvent, ErrorResponse } from "@h4x/common";
import { CEUtils, Type, ElementTypes, MapType } from "../CEUtils";
import { InputText } from "../../FormComponents";

class ElementContainer extends BasicComponent<{
	properties: ElementTypes;
	data: any;
	onUpdate: BasicEvent<any>;
	onDeleted: () => void;
}> {
	public override willReceiveProps() {
		this.update();
		this.initSchema();
	}

	public isBasic: boolean;
	@BasicComponent.initialize()
	private initSchema() {
		let schema = this.props.properties;
		this.isBasic = false;
		if (schema.type === Type.Boolean || schema.type === Type.Number) {
			this.isBasic = true;
		} else if (schema.type === Type.String && schema.multiline !== true) {
			this.isBasic = true;
		}
	}

	@BasicComponent.bind()
	private delete() {
		this.props.data[this.props.key] = undefined;
		this.props.onDeleted();
		this.props.onUpdate.execute();
	}

	private opened = true;
	@BasicComponent.bind()
	private toggle() {
		this.opened = !this.opened;
		this.update();
	}

	public render() {
		let { properties, data, key, onUpdate } = this.props;
		if (this.isBasic) {
			return <div style={{ "margin": "3px 0" }}>
				<i style={{ "margin-right": 13 }}></i>
				<span style={{ "min-width": 150, "display": "inline-block", "vertical-align": "top" }}>{key}</span>
				<i class="far fa-trash-alt" style={{ "color": "#C44", "margin-right": 5 }} onClick={this.delete}></i>
				{CEUtils.renderInput(properties, data, key, onUpdate)}
			</div>;
		} else if (this.opened) {
			return <div style={{ "margin": "3px 0" }}>
				<i class="fas fa-caret-down" style={{ "vertical-align": "top", "width": 10, "text-align": "center", "margin-right": 3 }} onClick={this.toggle}></i>
				<span style={{ "min-width": 150, "display": "inline-block", "vertical-align": "top" }}>{key}</span>
				<i class="far fa-trash-alt" style={{ "color": "#C44" }} onClick={this.delete}></i>
				{CEUtils.renderInput(properties, data, key, onUpdate)}
			</div>;
		} else {
			return <div style={{ "margin": "3px 0" }}>
				<i class="fas fa-caret-right" style={{ "vertical-align": "top", "width": 10, "text-align": "center", "margin-right": 3 }} onClick={this.toggle}></i>
				<span style={{ "min-width": 150, "display": "inline-block", "vertical-align": "top" }}>{key}</span>
				<i class="far fa-trash-alt" style={{ "color": "#C44" }} onClick={this.delete}></i>
			</div>;
		}
	}
}

export class MapContainer extends BasicComponent<{
	schema: MapType;
	data: any;
	onUpdate: BasicEvent<any>;
}> {

	public override willReceiveProps() {
		this.update();
	}

	public newName: string = "";
	@BasicComponent.bind()
	private addNew() {
		if (this.newName.length <= 0) {
			throw new ErrorResponse("New element needs to have a name!");
		}
		if (this.props.data[this.newName] !== undefined) {
			throw new ErrorResponse("New element needs to have a unique name!");
		}
		this.props.data[this.newName] = CEUtils.getNew(this.props.schema.elements);
		this.newName = "";
		this.update();
		this.props.onUpdate.execute();
	}

	@BasicComponent.bind()
	private onDeleted() {
		this.update();
	}

	public render() {
		let { schema, data, onUpdate } = this.props;
		let inputs: any[] = [];
		let properties = schema.elements;
		for (const key in data) {
			if (data.hasOwnProperty(key) && data[key] !== undefined) {
				inputs.push(<ElementContainer properties={properties} data={data} key={key} onUpdate={onUpdate} onDeleted={this.onDeleted} />);
			}
		}
		return <div style={{ "padding-left": 10, "margin-left": 15 }}>
			{inputs}
			<div>
				<InputText $={this.$("newName")} />
				<i class="fas fa-plus-circle" style={{ "color": "#4C4" }} onClick={this.addNew}></i>
			</div>
		</div>;
	}
}
