
type FunctionArgs<T extends Function> = T extends (...args: infer P) => any ? P : never;

export interface InvalidEventInterface<TFunc extends Function, TEvent> {
	readonly valid: false;
	readonly event?: TEvent;
	readonly func?: TFunc;
	readonly target?: any;

	remove(): void;
}

export interface ValidEventInterface<TFunc extends Function, TEvent> {
	readonly valid: true;
	readonly event: TEvent;
	readonly func: TFunc;
	readonly target?: any;

	remove(): void;
}

export type EventInterface<TFunc extends Function, TEvent> = InvalidEventInterface<TFunc, TEvent> | ValidEventInterface<TFunc, TEvent>;

class EventCallbackRef<T extends Function> {

	public readonly valid = true;

	constructor(public event: BasicEvent<T>, public func: T, public target?: any) { }

	public remove() {
		if (this.valid === true) {
			this.event!.removeCallbackRef(this);
			(<any>this).valid = false;
			this.event = <any>undefined;
			this.func = <any>undefined;
			this.target = undefined;
		}
	}
}

export class BasicEvent<TFunc extends Function> {
	protected callbacks: EventInterface<TFunc, BasicEvent<TFunc>>[] = [];

	protected executing: boolean = false;

	public static REMOVE_CALLBACK: any = <any>Symbol.for("Event::REMOVE");

	public addCallback(func: TFunc, thisArg?: any) {
		if (!func) {
			throw new Error("Callback can't be null!");
		}
		// Detect the usage of func.bind() - it kills optimization therefore is not allowed.
		if (typeof func.prototype !== "object" && (func as any).name.substr(0, 5) === "bound") {
			throw new Error("Don't use .bind() on functions! (" + (func as any).name + ")");
		}

		const ref = new EventCallbackRef<TFunc>(this, func, thisArg);
		this.callbacks.push(ref);

		return ref as EventInterface<TFunc, BasicEvent<TFunc>>;
	}

	public clearCallbacks() {
		this.callbacks = [];
	}

	/**
	 * Number of callbacks
	 */
	public get count() {
		return this.callbacks.length;
	}

	public removeCallback(func: TFunc, thisArg?: any) {
		const callbacks = this.executing ? Array.from(this.callbacks) : this.callbacks;
		for (let i = callbacks.length - 1; i >= 0; i--) {
			const callback = callbacks[i];
			if (callback.func === func && callback.target === thisArg) {
				callbacks.splice(i, 1);
			}
		}
		this.callbacks = callbacks;
	}

	public removeCallbackRef(ref: EventInterface<TFunc, BasicEvent<TFunc>>) {
		const callbacks = this.executing ? Array.from(this.callbacks) : this.callbacks;
		for (let i = callbacks.length - 1; i >= 0; i--) {
			const callback = callbacks[i];
			if (callback === ref) {
				callbacks.splice(i, 1);
			}
		}
		this.callbacks = callbacks;
	}

	public hasCallback(func: TFunc, thisArg?: any) {
		for (let i = this.callbacks.length - 1; i >= 0; i--) {
			const callback = this.callbacks[i];
			if (callback.func === func && callback.target === thisArg) {
				return true;
			}
		}
		return false;
	}

	public execute(...args: FunctionArgs<TFunc>) {
		this.executing = true;
		const callbacks = this.callbacks;
		const length = callbacks.length;
		for (let i = 0; i < length; i++) {
			const callback = callbacks[i];
			if (callback.valid === true) {
				const ret = callback.func.apply(callback.target, args);
				if (ret === BasicEvent.REMOVE_CALLBACK) {
					callback.remove();
				}
			}
		}
		this.executing = false;
	};
}

class PromiseEventCallbackRef<TFunc extends Function> {

	public readonly valid = true;

	constructor(public event: PromiseEvent<TFunc>, public func: TFunc, public target: any) { }

	public remove() {
		if (this.valid === true) {
			this.event.removeCallbackRef(this);
			(<any>this).valid = false;
			this.event = <any>undefined;
			this.func = <any>undefined;
			this.target = undefined;
		}
	}
}

export class PromiseEvent<TFunc extends Function> {
	protected callbacks: EventInterface<TFunc, PromiseEvent<TFunc>>[] = [];

	protected executing: boolean = false;

	public addCallback(func: TFunc, thisArg?: any) {
		if (!func) {
			throw new Error("Callback can't be null!");
		}
		// Detect the usage of func.bind() - it kills optimization therefore is not allowed.
		if (typeof func.prototype !== "object" && (func as any).name.substr(0, 5) === "bound") {
			throw new Error("Don't use .bind() on functions! (" + (func as any).name + ")");
		}

		const ref = new PromiseEventCallbackRef<TFunc>(this, func, thisArg);
		this.callbacks.push(ref);

		return ref as EventInterface<TFunc, PromiseEvent<TFunc>>;
	}

	public clearCallbacks() {
		this.callbacks = [];
	}

	/**
	 * Number of callbacks
	 */
	public get count() {
		return this.callbacks.length;
	}

	public removeCallback(func: TFunc, thisArg?: any) {
		const callbacks = this.executing ? Array.from(this.callbacks) : this.callbacks;
		for (let i = callbacks.length - 1; i >= 0; i--) {
			const callback = callbacks[i];
			if (callback.func === func && callback.target === thisArg) {
				callbacks.splice(i, 1);
			}
		}
		this.callbacks = callbacks;
	}

	public removeCallbackRef(ref: EventInterface<TFunc, PromiseEvent<TFunc>>) {
		const callbacks = this.executing ? Array.from(this.callbacks) : this.callbacks;
		for (let i = callbacks.length - 1; i >= 0; i--) {
			const callback = callbacks[i];
			if (callback === ref) {
				callbacks.splice(i, 1);
			}
		}
		this.callbacks = callbacks;
	}

	public hasCallback(func: TFunc, thisArg?: any) {
		for (let i = this.callbacks.length - 1; i >= 0; i--) {
			const callback = this.callbacks[i];
			if (callback.func === func && callback.target === thisArg) {
				return true;
			}
		}
		return false;
	}

	public async execute(...args: FunctionArgs<TFunc>) {
		this.executing = true;
		const callbacks = this.callbacks;
		const length = callbacks.length;
		for (let i = 0; i < length; i++) {
			const callback = callbacks[i];
			if (callback.valid === true) {
				const ret = await callback.func.apply(callback.target, args);
				if (ret === BasicEvent.REMOVE_CALLBACK) {
					callback.remove();
				}
			}
		}
		this.executing = false;
	};
}
