import { BasicComponent } from "../Components/BasicComponent";
import { VNode, h, render } from "./TSX";

import "./Overlay.scss";


export class Overlay extends BasicComponent<{}> {

	private visible = false;
	private queue: { vnode: VNode; instance: BasicComponent; }[] = [];
	private alerts: { vnode: VNode; instance: BasicComponent; }[] = [];
	private floating: { vnode: VNode; instance: BasicComponent; }[] = [];
	private static instance: Overlay;

	constructor() {
		super();
	}

	public static create(element: HTMLElement): any {
		if (this.instance) {
			console.error("Overlay already exists, overriding...");
		}
		render(<Overlay ref={(ref) => { this.instance = ref; console.log("ref", ref); }}></Overlay>, element);
	}

	private static uniqueID = 1;

	// node: ComponentFactory<P>, params: Attributes & P | null
	public static add(vnode: VNode) {
		let data = Overlay.createInstance(vnode);

		this.instance.queue.push(data as any);
		this.instance.update();
		return data;
	}

	public static remove(component: BasicComponent): any {
		Overlay.removeInstance(this.instance.queue, component);
		this.instance.update();
	}

	public static addAlert(vnode: VNode) {
		let data = Overlay.createInstance(vnode);

		this.instance.alerts.push(data as any);
		this.instance.update();
		return data;
	}

	public static removeAlert(component: BasicComponent): any {
		Overlay.removeInstance(this.instance.alerts, component);
		this.instance.update();
	}

	public static addFloating(vnode: VNode) {
		let data = Overlay.createInstance(vnode);

		this.instance.floating.push(data as any);
		this.instance.update();
		return data;
	}

	public static removeFloating(component: BasicComponent): any {
		Overlay.removeInstance(this.instance.floating, component);
		this.instance.update();
	}

	/*private interval: number;
	protected componentDidMount() {
		this.interval = setInterval(() => {
			//this.visible = !this.visible;
			this.softUpdate();
			// this.forceUpdate();
		}, 5000) as any;
	}

	protected componentWillUnmount() {
		clearInterval(this.interval);
	}*/

	public override postUpdate() {
		let count = this.queue.length;
		if (count > 0) {
			document.getElementsByTagName("body")[0].style.setProperty("overflow", "hidden");
		} else {
			document.getElementsByTagName("body")[0].style.setProperty("overflow", null);
		}
	}

	public render() {
		let count = this.queue.length;
		if (count > 0) {
			let current = this.queue[count - 1];
			let output = current.vnode;
			output.props["key"] = output.key; // restore key
			return <div id="overlay-inner-container">
				<div class="Overlay" key="Overlay" style={{ display: "flex" }}>
					{output}
				</div>
				<div class="OverlayAlerts" key="OverlayAlerts">
					{this.alerts.map((x) => x.vnode)}
				</div>
				<div class="OverlayFloating" key="OverlayFloating">
					{this.floating.map((x) => x.vnode)}
				</div>
			</div>;
		} else {
			return <div id="overlay-inner-container">
				<div class="Overlay" key="Overlay" style={{ display: "none" }}></div>
				<div class="OverlayAlerts" key="OverlayAlerts">
					{this.alerts.map((x) => x.vnode)}
				</div>
				<div class="OverlayFloating" key="OverlayFloating">
					{this.floating.map((x) => x.vnode)}
				</div>
			</div>;
		}
	}

	/* Helper Functions */
	private static createInstance(vnode: VNode<any>): { vnode: VNode; instance: BasicComponent; } {
		if (this.instance === undefined) {
			console.error("Overlay not found...");
		}

		let data = { vnode: vnode, instance: undefined };
		if (vnode.props === undefined) {
			vnode.props = {};
		}

		vnode.props["ref"] = (ref: any) => {
			data.instance = ref;
		};
		vnode.key = "key_" + (Overlay.uniqueID++);
		vnode.props["persistent"] = true;
		return data as any;
	}

	private static removeInstance(arr: { vnode: VNode; instance: BasicComponent; }[], component: BasicComponent<{}>) {
		if (this.instance === undefined) {
			console.error("Overlay not found...");
		}
		const index = arr.findIndex(e => e.instance === component);
		let removed = undefined;
		if (index !== -1) {
			console.log("removing", index);
			removed = arr.splice(index, 1)[0];
		}
		if (removed) {
			removed.vnode.key = undefined;
			removed.vnode.props["persistent"] = undefined;
			if (removed.instance !== undefined) {
				(removed.instance as any).__persistent = undefined;
				(removed.instance as any)[Symbol.for("$TSX_internal_Destroy")]();
			}
		}
	}
}
