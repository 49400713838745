export { };

declare global {
	interface Set<T> {
		map<U>(callback: (value: T, index: number) => U): U[];
		find<U>(callback: (value: T, index: number) => boolean): U | undefined;
	}
	interface Map<K, V> {
		map<U>(callback: (value: V, key: K) => U): U[];
	}
	interface Object {
		map<U, T extends Object>(this: T, callback: (value: T[keyof T], key: keyof T) => U): U[];
		mapKey<U, T extends Object>(this: T, callback: (key: keyof T) => U): U[];
	}
}

Set.prototype.map = function <T, U>(this: Set<T>, callback: (value: T, index: number) => U) {
	return Array.from(this, callback);
};

Set.prototype.find = function <T, U>(this: Set<T>, callback: (value: T, index: number) => boolean) {
	let index = 0;
	for (const value of this) {
		if (callback(value, index++)) {
			return value;
		}
	}
	return undefined;
};

Map.prototype.map = function <K, V, U>(this: Map<K, V>, callback: (value: V, key: K) => U) {
	return Array.from(this, ([k, v]) => callback(v, k));
};

Object.prototype.map = function <U, T>(this: T, callback: (value: T[keyof T], key: keyof T) => U) {
	return Object.entries(this).map(([key, value]) => callback(value, key as keyof T));
};

Object.prototype.mapKey = function <U, T>(this: T, callback: (key: keyof T) => U) {
	return Object.keys(this).map((key) => callback(key as keyof T));
};
