import { BufferError } from "./BufferError";
import { internalBytes, internalFloatArray, internalDoubleArray, AbstractBuffer, RawInternalBuffer, InternalBuffer, StringMode } from "./AbstractBuffer";
import { BinaryUtils } from "../utils/BinaryUtils";

export class SimpleBuffer extends Uint8Array {

	private bufferS: Int8Array;
	private _readPos: number;
	private _writePos: number;
	private _limit: number;

	constructor(buffer: ArrayBufferLike, private readonly offset: number, length: number) {
		super(buffer, offset, length);

		this.bufferS = new Int8Array(buffer, offset, length);
		this._readPos = 0;
		this._writePos = 0;

		this._limit = this.byteLength;
	}


	public static fromBuffer(buffer: Uint8Array) {
		if (!(buffer instanceof Uint8Array)) {
			throw new BufferError("Couldn't create SimpleBuffer(fromBuffer), invalid constructor argument " + typeof (buffer) + ", expected Uint8Array!");
		}
		return new SimpleBuffer(buffer.buffer, buffer.byteOffset, buffer.byteLength);
	}

	public static fromArrayBuffer(buffer: ArrayBuffer) {
		if (!(buffer instanceof ArrayBuffer)) {
			throw new BufferError("Couldn't create SimpleBuffer(fromArrayBuffer), invalid constructor argument " + typeof (buffer) + ", expected ArrayBuffer!");
		}
		return new SimpleBuffer(buffer, 0, buffer.byteLength);
	}

	public static allocate(size: number) {
		size = size | 0;
		return new SimpleBuffer(new ArrayBuffer(size), 0, size);
	}

	public subBuffer(start: number, length: number) {
		return new SimpleBuffer(this, this.offset + start, length);
	}

	set writePos(value: number) {
		if (value > this._limit) {
			throw new BufferError("[SimpleBuffer::writePos] Can't set writePos above buffer's capacity!");
		}
		if (value < 0) {
			throw new BufferError("[SimpleBuffer::writePos] Can't set writePos below 0!");
		}
		this._writePos = value;
	}

	get writePos(): number {
		return this._writePos;
	}

	set readPos(value: number) {
		if (value > this._limit) {
			throw new BufferError("[SimpleBuffer::readPos] Can't set readPos above buffer's capacity!");
		}
		if (value < 0) {
			throw new BufferError("[SimpleBuffer::readPos] Can't set readPos below 0!");
		}
		this._readPos = value;
	}

	get readPos(): number {
		return this._readPos;
	}

	set limit(value: number) {
		if (value > this.buffer.byteLength) {
			throw new BufferError("[SimpleBuffer::limit] Can't set limit above buffer's capacity!");
		}
		if (value < 0) {
			throw new BufferError("[SimpleBuffer::limit] Can't set limit below 0!");
		}
		this._limit = value;
	}

	get limit(): number {
		return this._limit;
	}

	public resetLimit() {
		this.limit = this.buffer.byteLength;
	}

	public restart() {
		this._readPos = 0;
		this._writePos = 0;
	}

	public writeBool(value: boolean) {
		if (this._limit < (this._writePos + 1)) {
			throw new BufferError("[SimpleBuffer::writeBool] buffer access out of bounds!");
		}
		this[this._writePos++] = (value ? 1 : -1);
	}

	public readBool(): boolean {
		if (this._limit < (this._readPos + 1)) {
			throw new BufferError("[SimpleBuffer::readBool] buffer access out of bounds!");
		}
		return (this[this._readPos++] === 1);
	}

	public writeUInt8(value: number) {
		if (this._limit < (this._writePos + 1)) {
			throw new BufferError("[SimpleBuffer::writeUByte] buffer access out of bounds!");
		}
		this[this._writePos++] = (value | 0);
	}

	public readUInt8(): number {
		if (this._limit < (this._readPos + 1)) {
			throw new BufferError("[SimpleBuffer::readUByte] buffer access out of bounds!");
		}
		return this[this._readPos++];
	}

	public writeInt8(value: number) {
		if (this._limit < (this._writePos + 1)) {
			throw new BufferError("[SimpleBuffer::writeByte] buffer access out of bounds!");
		}
		this.bufferS[this._writePos++] = (value | 0);
	}

	public readInt8(): number {
		if (this._limit < (this._readPos + 1)) {
			throw new BufferError("[SimpleBuffer::readByte] buffer access out of bounds!");
		}
		return this.bufferS[this._readPos++];
	}

	public writeInt16(value: number) {
		if (this._limit < (this._writePos + 2)) {
			throw new BufferError("[SimpleBuffer::writeInt16] buffer access out of bounds!");
		}
		value = value | 0;
		this.bufferS[this._writePos + 0] = (value >> 8);
		this[this._writePos + 1] = value;
		this._writePos += 2;
	}

	public readInt16(): number {
		if (this._limit < (this._readPos + 2)) {
			throw new BufferError("[SimpleBuffer::readInt16] buffer access out of bounds!");
		}
		const out = (this.bufferS[this._readPos + 0] << 8) | this[this._readPos + 1];
		this._readPos += 2;
		return out;
	}

	public writeUInt16(value: number) {
		if (this._limit < (this._writePos + 2)) {
			throw new BufferError("[SimpleBuffer::writeUInt16] buffer access out of bounds!");
		}
		value = value | 0;
		this[this._writePos + 0] = (value >> 8);
		this[this._writePos + 1] = value;
		this._writePos += 2;
	}

	public readUInt16(): number {
		if (this._limit < (this._readPos + 2)) {
			throw new BufferError("[SimpleBuffer::readUInt16] buffer access out of bounds!");
		}
		const out = (this[this._readPos + 0] << 8) | this[this._readPos + 1];
		this._readPos += 2;
		return out;
	}

	public writeInt24(value: number) {
		if (this._limit < (this._writePos + 3)) {
			throw new BufferError("[SimpleBuffer::writeInt24] buffer access out of bounds!");
		}
		value = value | 0;
		this.bufferS[this._writePos + 0] = (value >> 16);
		this[this._writePos + 1] = (value >> 8);
		this[this._writePos + 2] = value;
		this._writePos += 3;
	}

	public readInt24(): number {
		if (this._limit < (this._readPos + 3)) {
			throw new BufferError("[SimpleBuffer::readInt24] buffer access out of bounds!");
		}
		const out = (this.bufferS[this._readPos + 0] << 16) | (this[this._readPos + 1] << 8) | this[this._readPos + 2];
		this._readPos += 3;
		return out;
	}

	public writeUInt24(value: number) {
		if (this._limit < (this._writePos + 3)) {
			throw new BufferError("[SimpleBuffer::writeUInt24] buffer access out of bounds!");
		}
		value = value >>> 0;
		this[this._writePos + 0] = (value >> 16);
		this[this._writePos + 1] = (value >> 8);
		this[this._writePos + 2] = value;
		this._writePos += 3;
	}

	public readUInt24(): number {
		if (this._limit < (this._readPos + 3)) {
			throw new BufferError("[SimpleBuffer::readUInt24] buffer access out of bounds!");
		}
		const out = (this[this._readPos + 0] << 16) | (this[this._readPos + 1] << 8) | this[this._readPos + 2];
		this._readPos += 3;
		return out >>> 0;
	}

	public writeInt32(value: number) {
		if (this._limit < (this._writePos + 4)) {
			throw new BufferError("[SimpleBuffer::writeInt32] buffer access out of bounds!");
		}
		value = value | 0;
		this[this._writePos + 0] = (value >> 24);
		this[this._writePos + 1] = (value >> 16);
		this[this._writePos + 2] = (value >> 8);
		this[this._writePos + 3] = value;
		this._writePos += 4;
	}

	public readInt32(): number {
		if (this._limit < (this._readPos + 4)) {
			throw new BufferError("[SimpleBuffer::readInt32] buffer access out of bounds!");
		}
		const out = (this[this._readPos + 0] << 24) | (this[this._readPos + 1] << 16) | (this[this._readPos + 2] << 8) | this[this._readPos + 3];
		this._readPos += 4;
		return out;
	}

	public writeUInt32(value: number) {
		if (this._limit < (this._writePos + 4)) {
			throw new BufferError("[SimpleBuffer::writeInt32] buffer access out of bounds!");
		}
		value = value >>> 0;
		this[this._writePos + 0] = (value >> 24);
		this[this._writePos + 1] = (value >> 16);
		this[this._writePos + 2] = (value >> 8);
		this[this._writePos + 3] = value;
		this._writePos += 4;
	}

	public readUInt32(): number {
		if (this._limit < (this._readPos + 4)) {
			throw new BufferError("[SimpleBuffer::readInt32] buffer access out of bounds!");
		}
		const out = (this[this._readPos + 0] << 24) | (this[this._readPos + 1] << 16) | (this[this._readPos + 2] << 8) | this[this._readPos + 3];
		this._readPos += 4;
		return out >>> 0;
	}

	public writeFloat32(value: number) {
		if (this._limit < (this._writePos + 4)) {
			throw new BufferError("[SimpleBuffer::writeFloat32] buffer access out of bounds!");
		}
		internalFloatArray[0] = +value;
		this[this._writePos + 0] = (internalBytes[0] | 0);
		this[this._writePos + 1] = (internalBytes[1] | 0);
		this[this._writePos + 2] = (internalBytes[2] | 0);
		this[this._writePos + 3] = (internalBytes[3] | 0);
		this._writePos += 4;
	}

	public readFloat32(): number {
		if (this._limit < (this._readPos + 4)) {
			throw new BufferError("[SimpleBuffer::readFloat32] buffer access out of bounds!");
		}
		internalBytes[0] = this[this._readPos + 0];
		internalBytes[1] = this[this._readPos + 1];
		internalBytes[2] = this[this._readPos + 2];
		internalBytes[3] = this[this._readPos + 3];
		this._readPos += 4;
		return internalFloatArray[0];
	}

	public writeFloat64(value: number) {
		if (this._limit < (this._writePos + 8)) {
			throw new BufferError("[SimpleBuffer::writeFloat64] buffer access out of bounds!");
		}
		internalDoubleArray[0] = +value;
		this[this._writePos + 0] = (internalBytes[0] | 0);
		this[this._writePos + 1] = (internalBytes[1] | 0);
		this[this._writePos + 2] = (internalBytes[2] | 0);
		this[this._writePos + 3] = (internalBytes[3] | 0);
		this[this._writePos + 4] = (internalBytes[4] | 0);
		this[this._writePos + 5] = (internalBytes[5] | 0);
		this[this._writePos + 6] = (internalBytes[6] | 0);
		this[this._writePos + 7] = (internalBytes[7] | 0);
		this._writePos += 8;
	}

	public readFloat64(): number {
		if (this._limit < (this._readPos + 8)) {
			throw new BufferError("[SimpleBuffer::readFloat64] buffer access out of bounds!");
		}
		internalBytes[0] = this[this._readPos + 0];
		internalBytes[1] = this[this._readPos + 1];
		internalBytes[2] = this[this._readPos + 2];
		internalBytes[3] = this[this._readPos + 3];
		internalBytes[4] = this[this._readPos + 4];
		internalBytes[5] = this[this._readPos + 5];
		internalBytes[6] = this[this._readPos + 6];
		internalBytes[7] = this[this._readPos + 7];
		this._readPos += 8;
		return internalDoubleArray[0];
	}

	public setInt32(offset: number, value: number) {
		if (this._limit < (offset + 4)) {
			throw new BufferError("[SimpleBuffer::setInt32] buffer access out of bounds!");
		}
		offset = offset | 0;
		value = value | 0;
		this[offset] = (value >> 24);
		this[offset + 1] = (value >> 16);
		this[offset + 2] = (value >> 8);
		this[offset + 3] = value;
	}

	public writeBuffer(buffer: Uint8Array) {
		this.set(buffer, this._writePos);
		this._writePos += buffer.byteLength;
	}

	public readBuffer(size: number) {
		let buffer = this.buffer.slice(this._readPos, size);
		this._readPos += buffer.byteLength;
		return new Uint8Array(buffer);
	}

	public toBuffer() {
		return this.buffer.slice(0, this.writePos);
	}

	public toUint8Array() {
		return new Uint8Array(this.toBuffer());
	}

	//******************************************************************************/
	// From AbstractBuffer
	public writeUByte(value: number) {
		this.writeUInt8(value);
	}

	public readUByte(): number {
		return this.readUInt8();
	}

	public writeByte(value: number) {
		this.writeInt8(value);
	}

	public readByte(): number {
		return this.readInt8();
	}


	public readString(): string {
		const length = this.readUInt32();
		let buf = this.readBuffer(length);
		return this.stringFromBuffer(buf);
	}

	public writeString(string: string) {
		let buf = this.bufferFromString(string);
		this.writeUInt32(buf.byteLength);
		this.writeBuffer(buf);
	}

	public readVarInt() {
		let value = 0;
		let digits = 0;
		while (value <= 0) {
			let next = this.readInt8();
			if (next < 0) { value = (next << digits) + value; } else { value = (next << digits) - value; }
			digits += 7;
		}
		return value;
	}

	public writeVarInt(value: number) {
		while (value >= 128) { this.writeInt8(-(value & 0x7F)); value >>>= 7; } this.writeInt8((value & 0x7F));
	}

	public readStringMode(mode: StringMode): string {
		if (mode === StringMode.Fixed8) {
			const length = this.readUInt8();
			let buf = this.readBuffer(length);
			return this.stringFromBuffer(buf);
		} else if (mode === StringMode.Fixed16) {
			const length = this.readUInt16();
			let buf = this.readBuffer(length);
			return this.stringFromBuffer(buf);
		} else if (mode === StringMode.Fixed24) {
			const length = this.readUInt24();
			let buf = this.readBuffer(length);
			return this.stringFromBuffer(buf);
		} else if (mode === StringMode.Fixed32) {
			const length = this.readUInt32();
			let buf = this.readBuffer(length);
			return this.stringFromBuffer(buf);
		} else if (mode === StringMode.Dynamic) {
			let length = 0;
			let digits = 0;
			while (length <= 0) {
				let next = this.readInt8();
				if (next < 0) { length = (next << digits) + length; } else { length = (next << digits) - length; }
				digits += 7;
			}
			let buf = this.readBuffer(length);
			return this.stringFromBuffer(buf);
		} else {
			throw new Error("Invalid StringMode!");
		}
	}

	public writeStringMode(mode: StringMode, string: string) {
		if (mode === StringMode.Fixed8) {
			let buf = this.bufferFromString(string);
			this.writeUInt8(buf.byteLength);
			this.writeBuffer(buf);
		} else if (mode === StringMode.Fixed16) {
			let buf = this.bufferFromString(string);
			this.writeUInt16(buf.byteLength);
			this.writeBuffer(buf);
		} else if (mode === StringMode.Fixed24) {
			let buf = this.bufferFromString(string);
			this.writeUInt24(buf.byteLength);
			this.writeBuffer(buf);
		} else if (mode === StringMode.Fixed32) {
			let buf = this.bufferFromString(string);
			this.writeUInt32(buf.byteLength);
			this.writeBuffer(buf);
		} else if (mode === StringMode.Dynamic) {
			let buf = this.bufferFromString(string);
			let length = buf.byteLength;
			while (length >= 128) { this.writeInt8(-(length & 0x7F)); length >>>= 7; }
			this.writeInt8(length);
			this.writeBuffer(buf);
		} else {
			throw new Error("Invalid StringMode!");
		}
	}

	public bufferFromString(string: string) {
		// browser optimization
		if ((global as any).TextEncoder !== undefined) {
			let encoder = new TextEncoder();
			return encoder.encode(string);
		}
		return BinaryUtils.stringToUint8Array(string);
	}

	public stringFromBuffer(buffer: Uint8Array) {
		// browser optimization
		if ((global as any).TextDecoder !== undefined) {
			let decoder = new TextDecoder("utf-8");
			return decoder.decode(buffer);
		}
		return BinaryUtils.uint8ToStringArray(buffer);
	}

}