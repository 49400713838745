import { h } from "../TSX/TSX";
import { $Proxy, BasicComponent } from "../Components/BasicComponent";

export interface InputProps<T> extends JSX.HTMLAttributes<HTMLInputElement> {
	$: $Proxy<T>;
	value?: undefined;
	onInput?: undefined;
	onChange?: undefined;
}

export abstract class BasicInput<T extends { $: $Proxy<any> }> extends BasicComponent<T> {
	public override init() {
		this.props.$.onUpdate.addCallback(this.update, this);
	}

	public override destroy() {
		this.props.$.onUpdate.removeCallback(this.update, this);
	}

	public override willReceiveProps() {
		this.update();
	}
}

export class InputText extends BasicInput<InputProps<string>> {

	@BasicComponent.bind()
	protected onInput(event: any) {
		let value = event.target.value;
		this.props.$.set(value);
		this.props.$.onUpdate.execute(value);
	}

	@BasicComponent.bind()
	protected onChange(event: any) {
		let value = event.target.value;
		this.props.$.onChange.execute(value);
	}

	public render() {
		let { $, ref, ...props } = this.props;
		return <input type="text" {...props} value={$.get()} onInput={this.onInput} onChange={this.onChange} />;
	}
}

export class InputNumber extends BasicInput<InputProps<number>> {

	@BasicComponent.bind()
	protected onInput(event: any) {
		let value = +event.target.value;
		this.props.$.set(value);
		this.props.$.onUpdate.execute(value);
	}

	@BasicComponent.bind()
	protected onChange(event: any) {
		let value = event.target.value;
		this.props.$.onChange.execute(value);
	}

	public render() {
		let { $, ref, ...props } = this.props;
		return <input type="number" {...props} value={$.get()} onInput={this.onInput} onChange={this.onChange} />;
	}
}

export class InputRange extends BasicInput<InputProps<number>> {

	@BasicComponent.bind()
	protected onInput(event: any) {
		let value = +event.target.value;
		this.props.$.set(value);
		this.props.$.onUpdate.execute(value);
	}

	@BasicComponent.bind()
	protected onChange(event: any) {
		let value = event.target.value;
		this.props.$.onChange.execute(value);
	}

	public render() {
		let { $, ref, ...props } = this.props;
		return <input type="range" {...props} value={$.get()} onInput={this.onInput} onChange={this.onChange} />;
	}
}


export interface InputTextBoxProps<T> extends JSX.HTMLAttributes<HTMLTextAreaElement> {
	$: $Proxy<T>;
	value?: undefined;
	onInput?: undefined;
}
export class InputTextBox extends BasicInput<InputTextBoxProps<string>> {

	@BasicComponent.bind()
	protected onInput(event: any) {
		let value = event.target.value;
		this.props.$.set(value);
		this.props.$.onUpdate.execute(value);
	}

	@BasicComponent.bind()
	protected onChange(event: any) {
		let value = event.target.value;
		this.props.$.onChange.execute(value);
	}

	public render() {
		let { $, ref, ...props } = this.props;
		return <textarea {...props} value={$.get()} onInput={this.onInput} onChange={this.onChange} />;
	}
}

export class InputCheckbox extends BasicInput<InputProps<boolean>> {

	@BasicComponent.bind()
	protected onInput(event: any) {
		let value = event.target.checked;
		this.props.$.set(value);
		this.props.$.onUpdate.execute(value);
	}

	@BasicComponent.bind()
	protected onChange(event: any) {
		let value = event.target.value;
		this.props.$.onChange.execute(value);
	}

	public render() {
		let { $, ref, ...props } = this.props;
		return <input type="checkbox" {...props} checked={$.get()} onInput={this.onInput} onChange={this.onChange} />;
	}
}


/*
Static "inputs"
*/
export class BasicText extends BasicInput<InputProps<string | number>> {
	public render() {
		let { $, ref, ...props } = this.props;
		return <span {...props}>{$.get()}</span>;
	}
}

export class FixedNumber extends BasicInput<InputProps<number> & { digits: number; multiplier?: number; prefix?: string; postfix?: string; }> {
	public render() {
		let { $, digits, multiplier, prefix, postfix, ref, ...props } = this.props;
		let number = $.get();
		if (multiplier !== undefined) {
			number *= multiplier;
		}
		return <span {...props}>{prefix}{number.toFixed(digits)}{postfix}</span>;
	}
}

export class InputSelect extends BasicInput<{ values: { [K: string]: string } | string[] } & InputProps<string>> {

	@BasicComponent.bind()
	protected onInput(event: any) {
		let value = event.target.value;
		this.props.$.set(value);
		this.props.$.onUpdate.execute(value);
	}

	@BasicComponent.bind()
	protected onChange(event: any) {
		let value = event.target.value;
		this.props.$.onChange.execute(value);
	}

	public render() {
		let { $, values, ref, ...props } = this.props;
		let current = $.get();
		let inputs: JSX.Element[] = [];

		if (Array.isArray(values)) {
			inputs = values.map(key => <option value={key} selected={key === current}>{key}</option>);
		} else {
			inputs = [];
			for (const key in values) {
				if (values.hasOwnProperty(key)) {
					const value = values[key];
					inputs.push(<option value={key} selected={key === current}>{value}</option>);
				}
			}
		}

		return <select {...props} onInput={this.onInput} onChange={this.onChange}>{inputs}</select>;
	}
}

/*
Specialized inputs
*/
export class InputFile extends BasicComponent<{ onFile: (file: File | undefined) => void } & JSX.HTMLAttributes<HTMLInputElement>> {

	@BasicComponent.bind()
	protected onChange(event: { target: HTMLInputElement }) {
		let value = event.target.files != null ? event.target.files[0] : undefined;
		this.props.onFile(value);
	}

	public render() {
		let { ref, ...props } = this.props;
		return <input type="file" {...props} onChange={this.onChange as any} />; // FIXME
	}
}

export class InputFiles extends BasicComponent<{ onFiles: (files: File[] | undefined) => void } & JSX.HTMLAttributes<HTMLInputElement>> {

	@BasicComponent.bind()
	protected onChange(event: { target: HTMLInputElement }) {
		let value = event.target.files != null ? Array.from(event.target.files) : undefined;
		this.props.onFiles(value);
	}

	public render() {
		let { ref, ...props } = this.props;
		return <input type="file" {...props} onChange={this.onChange as any} multiple />; // FIXME
	}
}
