import { BinaryUtils } from "./BinaryUtils";

export class Base64 {
	public static readonly default = new Base64("ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/");
	public static readonly web = new Base64("ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_");

	private chars: string[];
	private rev: { [K: string]: number };

	constructor(map: string) {
		if (map.length !== 64) {
			throw new Error();
		}
		let chars: string[] = [];
		for (let i = 0; i < map.length; i++) {
			chars.push(map.charAt(i));
		}

		let rev: { [K: string]: number } = {};
		chars.forEach((c, i) => { rev[c] = i; });

		this.chars = chars;
		this.rev = rev;
	}

	public encodeString(text: string) {
		return this.encode(BinaryUtils.stringToUint8Array(text));
	}

	public encode(array: Uint8Array) {
		let chars = this.chars;
		let out = "";
		let length = array.length;
		let groups = Math.floor(array.length / 3);

		for (let g = 0; g < groups; g++) {
			let number = 0;
			for (let i = 0; i < 3; i++) {
				number = (number * 256) + array[g * 3 + i];
			}
			let divider = 64 * 64 * 64;
			for (let i = 0; i < 4; i++) {
				out += chars[((number / divider) >>> 0) % 64];
				divider /= 64;
			}
		}

		let lastIndex = groups * 3;
		let lastSize = length - lastIndex;
		if (lastSize !== 0) {
			let number = 0;
			for (let i = 0; i < lastSize; i++) {
				number = (number * 256) + array[lastIndex + i];
			}

			let divider = 64;
			if (lastSize === 2) {
				divider = 64 * 64;
				number <<= 2;
			}

			if (lastSize === 1) {
				number <<= 4;
			}

			for (let i = 0; i < lastSize + 1; i++) {
				out += chars[((number / divider) >>> 0) % 64];
				divider /= 64;
			}
		}

		return out;
	}

	public decodeString(text: string) {
		return BinaryUtils.uint8ToStringArray(this.decode(text));
	}

	public decode(array: string) {
		let rev = this.rev;

		let length = array.length;
		if (array[length - 1] === "=") { length -= 1; }
		if (array[length - 1] === "=") { length -= 1; }
		if (array[length - 1] === "=") { length -= 1; }
		let groups = Math.floor(length / 4);
		let lastIndex = groups * 4;
		let lastSize = length - lastIndex;

		let out = new Uint8Array(groups * 3 + (lastSize > 1 ? lastSize - 1 : 0));
		let index = 0;

		for (let g = 0; g < groups; g++) {
			let number = 0;
			for (let i = 0; i < 4; i++) {
				number = number * 64 + rev[array[g * 4 + i]];
			}
			let divider = 256 * 256;
			for (let i = 0; i < 3; i++) {
				out[index++] = ((number / divider) >>> 0) % 256;
				divider /= 256;
			}
		}

		if (lastSize !== 0) {
			let number = 0;
			for (let i = 0; i < lastSize; i++) {
				number = number * 64 + rev[array[(lastIndex) + i]];
			}

			let divider = 1;
			if (lastSize === 3) {
				divider = 256;
				number >>= 2;
			}

			if (lastSize === 2) {
				number >>= 4;
			}

			for (let i = 0; i < lastSize - 1; i++) {
				out[index++] = ((number / divider) >>> 0) % 256;
				divider /= 256;
			}
		}

		return out;
	}

	public packObject(data: any) {
		return this.encodeString(JSON.stringify(data));
	}

	public unpackObject(data: string) {
		return JSON.parse(this.decodeString(data));
	}
}
