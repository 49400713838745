import { h } from "../../../TSX/TSX";
import { BasicComponent } from "../../BasicComponent";
import { BasicEvent } from "@h4x/common";
import { CEUtils, Type, ElementTypes, ObjectType } from "../CEUtils";

class ElementContainer extends BasicComponent<{
	properties: ElementTypes;
	data: any;
	onUpdate: BasicEvent<any>;
}> {
	public override willReceiveProps() {
		this.update();
		this.initSchema();
	}

	public isBasic: boolean;
	@BasicComponent.initialize()
	private initSchema() {
		let schema = this.props.properties;
		this.isBasic = false;
		if (schema.type === Type.Boolean || schema.type === Type.Number) {
			this.isBasic = true;
		} else if (schema.type === Type.String && schema.multiline !== true) {
			this.isBasic = true;
		}
	}

	private opened = true;
	@BasicComponent.bind()
	private toggle() {
		this.opened = !this.opened;
		this.update();
	}

	public render() {
		let { properties, data, key, onUpdate } = this.props;
		if (this.isBasic) {
			return <div style={{ "margin": "3px 0" }}>
				<i style={{ "margin-right": 13 }}></i>
				<span style={{ "min-width": 150, "display": "inline-block", "vertical-align": "top" }}>{key}</span>
				{CEUtils.renderInput(properties, data, key, onUpdate)}
			</div>;
		} else if (this.opened) {
			return <div style={{ "margin": "3px 0" }}>
				<i class="fas fa-caret-down" style={{ "vertical-align": "top", "width": 10, "text-align": "center", "margin-right": 3 }} onClick={this.toggle}></i>
				<span style={{ "min-width": 150, "display": "inline-block", "vertical-align": "top" }}>{key}</span>
				{CEUtils.renderInput(properties, data, key, onUpdate)}
			</div>;
		} else {
			return <div style={{ "margin": "3px 0" }}>
				<i class="fas fa-caret-right" style={{ "vertical-align": "top", "width": 10, "text-align": "center", "margin-right": 3 }} onClick={this.toggle}></i>
				<span style={{ "min-width": 150, "display": "inline-block", "vertical-align": "top" }}>{key}</span>
			</div>;
		}
	}
}

export class ObjectContainer extends BasicComponent<{
	schema: ObjectType;
	data: any;
	onUpdate: BasicEvent<any>;
}> {

	public override willReceiveProps() {
		this.update();
	}

	public render() {
		let { schema, data, onUpdate } = this.props;
		let inputs: any[] = [];
		let properties = schema.properties;

		for (const key in properties) {
			if (properties.hasOwnProperty(key)) {
				inputs.push(<ElementContainer properties={properties[key]} data={data} key={key} onUpdate={onUpdate} />);
			}
		}
		return <div style={{ "padding-left": 10, "margin-left": 15 }}>
			{inputs}
		</div>;
	}
}
