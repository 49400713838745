import { h } from "../../TSX/TSX";
import { TooltipProvider } from "../Tooltip";
import { BasicEvent } from "@h4x/common";
import { ObjectContainer } from "./Containers/ObjectContainer";
import { MapContainer } from "./Containers/MapContainer";
import { ArrayContainer } from "./Containers/ArrayContainer";
import { BooleanContainer } from "./Containers/BooleanContainer";
import { NumberContainer } from "./Containers/NumberContainer";
import { TextContainer } from "./Containers/TextContainer";
import { ValidatorOutput } from "./CEValidator";

export enum Type {
	// Null,
	Unknown = "unknown",
	Boolean = "boolean",
	Number = "number",
	String = "string",
	Object = "object",
	Array = "array",
	Map = "map"
}

export interface ElementType {
	type: Type;
	optional?: boolean;
}

export interface BooleanType extends ElementType {
	type: Type.Boolean;
	default?: boolean;
}

export interface NumberType extends ElementType {
	type: Type.Number;
	default?: number;

	min?: number;
	max?: number;
}

export interface StringType extends ElementType {
	type: Type.String;
	default?: string;

	multiline?: boolean;
	options?: string[];
}

export interface ObjectType extends ElementType {
	type: Type.Object;
	properties: { [K: string]: ElementTypes };
}

export interface ArrayType extends ElementType {
	type: Type.Array;
	elements: ElementTypes;
}

export interface MapType extends ElementType {
	type: Type.Map;
	elements: ElementTypes;
}

export type ElementTypes = BooleanType | NumberType | StringType | ObjectType | ArrayType | MapType;
export type ContainerTypes = ObjectType | ArrayType | MapType;

export namespace CEUtils {

	export function getNew(schema: ElementTypes) {
		if (schema.type === Type.Object) {
			let out: any = {};
			for (const key in schema.properties) {
				if (schema.properties.hasOwnProperty(key)) {
					const property = schema.properties[key];
					out[key] = getNew(property);
				}
			}
			return out;
		} else if (schema.type === Type.Map) {
			return {};
		} else if (schema.type === Type.Array) {
			return [];
		} else if (schema.type === Type.Boolean) {
			if (schema.default !== undefined) { return schema.default; }
			if (schema.optional === true) { return undefined; }
			return false;
		} else if (schema.type === Type.Number) {
			if (schema.default !== undefined) { return schema.default; }
			if (schema.optional === true) { return undefined; }
			if (schema.min !== undefined) { return schema.min; }
			return 0;
		} else if (schema.type === Type.String) {
			if (schema.default !== undefined) { return schema.default; }
			if (schema.optional === true) { return undefined; }
			if (schema.options !== undefined && schema.options.length > 0) { return schema.options[0]; }
			return "";
		} else {
			throw new Error("Invalid schema type");
		}
	}

	export function renderInput(schema: any, data: any, key: string, onUpdate: BasicEvent<any>) {
		if (data === undefined) {
			throw new Error("Missing data");
		}
		if (schema.type === undefined) {
			throw new Error("Missing schema type");
		}

		if (schema.type === Type.Object) {
			return <ObjectContainer schema={schema} data={data[key]} onUpdate={onUpdate} />;
		} else if (schema.type === Type.Map) {
			return <MapContainer schema={schema} data={data[key]} onUpdate={onUpdate} />;
		} else if (schema.type === Type.Array) {
			return <ArrayContainer schema={schema} data={data[key]} onUpdate={onUpdate} />;
		} else if (schema.type === Type.Boolean) {
			return <BooleanContainer schema={schema} data={data} key={key} onUpdate={onUpdate} />;
		} else if (schema.type === Type.Number) {
			return <NumberContainer schema={schema} data={data} key={key} onUpdate={onUpdate} />;
		} else if (schema.type === Type.String) {
			return <TextContainer schema={schema} data={data} key={key} onUpdate={onUpdate} />;
		} else {
			throw new Error("Invalid schema type");
		}
	}

	export function parseInfo(data: ValidatorOutput) {
		let extra: JSX.Element[] = [];
		if (data.info.length > 0) {
			extra.push(<TooltipProvider renderTooltip={() => <div class="TooltipContent">{data.info.map(x => <div>{x}</div>)}</div>}>
				<i class="fas fa-info-circle" style={{ "margin-left": 5, "color": "#88B" }}></i>
			</TooltipProvider>);
		}

		if (data.warnings.length > 0) {
			extra.push(<TooltipProvider renderTooltip={() => <div class="TooltipContent">{data.warnings.map(x => <div>{x}</div>)}</div>}>
				<i class="fas fa-exclamation-triangle" style={{ "margin-left": 5, "color": "orange" }}></i>
			</TooltipProvider>);
		}

		if (data.errors.length > 0) {
			extra.push(<TooltipProvider renderTooltip={() => <div class="TooltipContent">{data.errors.map(x => <div>{x}</div>)}</div>}>
				<i class="far fa-times-circle" style={{ "margin-left": 5, "color": "red" }}></i>
			</TooltipProvider>);
		}
		return extra;
	}
}
