import { Component } from "../TSX/TSX";
import { ClassVariable, ClassMeta, GetClassMeta, RegisterClassVariable, BasicEvent, EventInterface } from "@h4x/common";

class IntervalVariable {
	public name: string;
	constructor(public time: number) { }
	public register(variable: ClassVariable) {
		this.name = variable.name;
	}
}
const INTERVAL = ClassVariable.createType<IntervalVariable>("IntervalVariable");

class InitializeVariable {
	public name: string;
	constructor() { }
	public register(variable: ClassVariable) {
		this.name = variable.name;
	}
}
const INITIALIZE = ClassVariable.createType<InitializeVariable>("InitializeVariable");

export type $Proxy<T> = {
	get: () => T;
	set: (variable: T) => void;
	onUpdate: BasicEvent<(value?: T) => void>;
	onChange: BasicEvent<(value?: T) => void>;
};


const metaSymbol = Symbol.for("$Meta");

export abstract class BasicComponent<P = {}> extends Component<P> {
	private [metaSymbol]: ClassMeta;
	private attachedEventReferences: EventInterface<any, any>[] = [];

	protected onDestroy = new BasicEvent<() => void>();

	constructor() {
		super();
		this.setupClass();
	}

	private setupClass() {
		this[metaSymbol] = GetClassMeta(this, true);
		if (this[metaSymbol].isRegistered() === false) {
			this[metaSymbol].register(false);
			// meta.forEachVariable();
		}
	}

	public proxy<T = this, K extends keyof T = keyof T>(key: K): $Proxy<T[K]> {
		return this.$(key);
	}

	public static var() {
		return (target: any, key: string) => {
			let internalVar = Symbol.for("var__" + key);
			Object.defineProperty(target, key, {
				get: function (this: any) {
					return this[internalVar];
				},
				set: function (this: any, value: any) {
					this[internalVar] = value;
					this.$(key).onUpdate.execute(value);
				},
				enumerable: true
			});
		};
	}

	public $<T = this, K extends keyof T = keyof T>(key: K): $Proxy<T[K]> {
		// change to private map?
		const sym = Symbol.for("^" + key);
		let proxy: $Proxy<any> = (this as any)[sym];
		if (proxy === undefined) {
			proxy = {
				get: () => {
					return (this as any)[key];
				},
				set: (variable: T[K]) => {
					(this as any)[key] = variable;
				},
				onUpdate: new BasicEvent<(value?: T[K]) => void>(),
				onChange: new BasicEvent<(value?: T[K]) => void>()
			};
			(this as any)[sym] = proxy;
		}
		return proxy;
	}

	public static override bind<T extends BasicComponent<any>>() {
		// bind in initialize/constructor to avoid internal class changes?
		return (target: T, key: string): void => {
			if (target instanceof BasicComponent === false) {
				throw new Error("Failed to bind, class needs to be a subclass of BasicComponent!");
			}

			let descriptor = Object.getOwnPropertyDescriptor(target, key)!;
			if (!descriptor || (typeof descriptor.value !== "function")) {
				throw new Error(`Bind can only be used on function! ${key} is not a function!`);
			}

			// let variable = RegisterClassVariable(target.constructor, key).attachData(BIND, new BindVariable());

			return {
				configurable: true,
				get(this: T): T {
					const fnBound: T = ((...args: any[]) => { return descriptor.value.apply(this, args); }) as any as T;

					Object.defineProperty(this, key, {
						value: fnBound,
						configurable: true,
						writable: true
					});
					return fnBound;
				}
			} as any as undefined;
		};
	}

	public static interval<T extends BasicComponent<any>>(time: number) {
		return function (target: T, key: string) {
			if (target instanceof BasicComponent === false) {
				throw new Error("Failed to attach interval, class needs to be a subclass of BasicComponent!");
			}
			RegisterClassVariable(target.constructor, key).attachData(INTERVAL, new IntervalVariable(time));
		};
	}

	public static initialize<T extends BasicComponent<any>>() {
		return function (target: T, key: string) {
			if (target instanceof BasicComponent === false) {
				throw new Error("Failed to attach initialize, class needs to be a subclass of BasicComponent!");
			}
			RegisterClassVariable(target.constructor, key).attachData(INITIALIZE, new InitializeVariable());
		};
	}

	private __init() {
		this[metaSymbol].forEachVariable((variable, key) => {
			let intervalData = variable.getData(INTERVAL)!;
			if (intervalData !== undefined) {

				// intervalData.time
				let interval = window.setInterval(() => { (this as any)[key](); }, intervalData.time);

				this.onDestroy.addCallback(() => {
					if (interval !== undefined) {
						clearInterval(interval);
					}
				});
			}

			let initializeData = variable.getData(INITIALIZE)!;
			if (initializeData !== undefined) {
				(this as any)[key]();
			}
		});
	}

	private __destroy() {
		this.onDestroy.execute();
		for (const ref of this.attachedEventReferences) { ref.remove(); }
	}

	/** Attach event reference to be cleaned up when the object is destroyed */
	protected ref<T extends EventInterface<any, any>>(ref: T) {
		this.attachedEventReferences.push(ref);
		return ref;
	}

	public static normalizeClass(target: any): undefined | { [K: string]: boolean } {
		if (Array.isArray(target)) {
			let out: any = {};
			for (const cls of target) {
				out[cls] = true;
			}
			return out;
		} else if (typeof (target) === "object") {
			return target;
		} else if (typeof (target) === "string") {
			let out: any = {};
			for (const cls of target.split(" ")) {
				out[cls] = true;
			}
			return out;
		}
		return undefined;
	}
}