import { BasicEvent } from "@h4x/common";

export class ListContainer<T> {
	private internal: T[] = [];

	constructor(...init: T[]) {
		this.internal = [...init];
	}

	get size() {
		return this.internal.length;
	}

	public set(list: T[]) {
		this.internal = list;
		this.onChanged.execute();
	}

	public get(index: number) {
		return this.internal[index];
	}

	public add(value: T) {
		let ret = this.internal.push(value);
		this.onChanged.execute();
		return ret;
	}

	public remove(index: number) {
		let removed = this.internal.splice(index, 1)[0];
		this.onChanged.execute();
		this.onRemove.execute(removed, index);
		return this.internal.length;
	}

	public removeValue(value: T) {
		let index = this.internal.indexOf(value);
		if (index === -1) { return this.internal.length; }
		return this.remove(index);
	}

	public each(callback: (value: T, index: number) => void) {
		this.internal.forEach(callback);
	}

	public map(callback: (value: T, index: number) => void) {
		return this.internal.map(callback);
	}

	public onChanged = new BasicEvent<() => void>();
	public onRemove = new BasicEvent<(value: T, index: number) => void>();
}
