import { h } from "../../../TSX/TSX";
import { BasicComponent } from "../../BasicComponent";
import { BasicEvent } from "@h4x/common";
import { Type, ContainerTypes } from "../CEUtils";
import { ObjectContainer } from "./ObjectContainer";
import { MapContainer } from "./MapContainer";
import { ArrayContainer } from "./ArrayContainer";

export class RootContainer extends BasicComponent<{
	name: string;
	schema: ContainerTypes;
	data: any;
	onUpdate: BasicEvent<any>;
} & JSX.HTMLAttributes<HTMLDivElement>> {

	public override willReceiveProps() {
		this.update();
	}

	public render() {
		let { name, schema, data, onUpdate, ...props } = this.props;
		if (schema.type === Type.Object) {
			return <div {...props}>
				<span style={{ "min-width": 150, "display": "inline-block", "vertical-align": "top" }}>{name}: </span>
				<ObjectContainer schema={schema} data={data} onUpdate={onUpdate} />
			</div>;
		} else if (schema.type === Type.Map) {
			return <div {...props}>
				<span style={{ "min-width": 150, "display": "inline-block", "vertical-align": "top" }}>{name}: </span>
				<MapContainer schema={schema} data={data} onUpdate={onUpdate} />
			</div>;
		} else if (schema.type === Type.Array) {
			return <div {...props}>
				<span style={{ "min-width": 150, "display": "inline-block", "vertical-align": "top" }}>{name}: </span>
				<ArrayContainer schema={schema} data={data} onUpdate={onUpdate} />
			</div>;
		}
		throw new Error("Invalid schema type");
	}
}