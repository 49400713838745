import { h } from "../../../TSX/TSX";
import { BasicComponent } from "../../BasicComponent";
import { BasicEvent, Utils } from "@h4x/common";
import { ValidatorOutput, Validator } from "../CEValidator";
import { CEUtils, StringType } from "../CEUtils";

export class TextContainer extends BasicComponent<{
	schema: StringType;
	data: any;
	key: any;
	onUpdate: BasicEvent<any>;
	value?: undefined;
	onInput?: undefined;
}> {
	public override init() {
		this.validate();
	}

	public override willReceiveProps() {
		this.update();
	}

	public get(): string {
		return this.props.data[this.props.key];
	}

	public set(value: string) {
		this.props.data[this.props.key] = value;
	}

	@BasicComponent.bind()
	private onInput(event: any) {
		let value = event.target.value;
		if (value.length <= 0) {
			value = undefined;
		}
		this.set(value);
		this.props.onUpdate.execute(value);
		this.validate();
	}


	private validatorData: ValidatorOutput;

	@BasicComponent.bind()
	private validate() {
		let oldData = this.validatorData;
		this.validatorData = Validator.text(this.props.schema, this.get(), this.props.data, this.props.key);

		if (Utils.equals(oldData, this.validatorData) === false) {
			console.log("validate...");
			this.update();
		}
	}

	public render() {
		let extra = CEUtils.parseInfo(this.validatorData);

		let input;
		if (this.props.schema.options !== undefined) {
			let values = this.props.schema.options;
			let current = this.get();
			let inputs = values.map(key => <option value={key} selected={key === current}>{key}</option>);
			input = <select class="InputContainer TextContainer Options" value={current} onInput={this.onInput}>{inputs}</select>;
		} else if (this.props.schema.multiline === true) {
			input = <textarea class="InputContainer TextContainer Multiline" value={this.get()} onInput={this.onInput} />;
		} else {
			input = <input class="InputContainer TextContainer" type="text" value={this.get()} onInput={this.onInput} />;
		}

		return <div style={{ "display": "inline-block" }}>
			{input}
			{extra}
		</div>;
	}
}
