import { h } from "../TSX/TSX";
import { BasicEvent } from "@h4x/common";
import { ListContainer } from "./ListContainer";
import { BasicComponent } from "./BasicComponent";

import "./ListSelect.scss";

class ListElement extends BasicComponent<{ options: SelectContainer; name: string; index: number; labelClass?: string | { [K: string]: boolean | undefined; }; }> {

	private selected = false;
	private options: SelectContainer;

	public override init() {
		this.options = this.props.options;
		{
			let index = this.options.index;
			this.selected = (index === this.props.index);
		}

		this.options.onSelected.addCallback(this.checkSelected, this);
	}

	public override willReceiveProps(nextProps: Readonly<{ options: SelectContainer; name: string }>) {
		if (this.props.name !== nextProps.name) {
			this.update();
		}
		if (this.options !== nextProps.options) {
			this.options.onSelected.removeCallback(this.checkSelected, this);
			nextProps.options.onSelected.addCallback(this.checkSelected, this);
		}
	}

	private checkSelected() {
		let index = this.options.index;

		let isSelected = (index === this.props.index);
		if (this.selected !== isSelected) {
			this.selected = isSelected;
			this.update();
		}
	}


	@BasicComponent.bind()
	public select() {
		this.options.onSelected.execute(this.props.index);
	}

	public render() {
		let labelClass = this.props !== undefined ? this.props.labelClass : undefined;
		let clazz: { [K: string]: boolean | undefined } = {};
		clazz["ListElement"] = true;
		if (typeof (labelClass) === "string") {
			clazz = { ...clazz, [labelClass]: true };
		} else if (labelClass !== undefined) {
			clazz = { ...clazz, ...labelClass };
		}
		if (this.selected === true) { clazz["selected"] = true; }
		return <div key={this.props.name} class={clazz} onClick={this.select}>
			{this.props.name}
		</div>;
	}
}

export class SelectContainer extends ListContainer<string | ({ [K: string]: any; label: string; labelClass?: string | { [K: string]: boolean | undefined; }; })> {
	public onSelected = new BasicEvent<(index: number) => void>();

	private currentIndex = -1;

	get index() {
		return this.currentIndex;
	}

	constructor(...init: (string | ({ [K: string]: any; label: string; labelClass?: string | { [K: string]: boolean | undefined; }; }))[]) {
		super(...init);

		this.onSelected.addCallback((index) => {
			this.currentIndex = index;
		});

		this.onRemove.addCallback((_value, index) => {
			if (index === this.currentIndex) {
				this.prev();
			} else if (index < this.currentIndex) {
				this.currentIndex--;
			}
		});
	}

	public linkValue<C, F extends keyof C = keyof C>(variable: C, field: F) {
		this.onSelected.addCallback((index) => {
			(variable[field] as any) = this.get(index);
		});
		return true;
	}

	public update(index: number) {
		if (this.currentIndex !== index) {
			this.currentIndex = index;
			this.onSelected.execute(index);
		}
	}

	public prev() {
		if (this.currentIndex - 1 >= 0) {
			this.currentIndex--;
			this.onSelected.execute(this.currentIndex);
		}
	}

	public next() {
		if (this.currentIndex + 1 < this.size) {
			this.currentIndex++;
			this.onSelected.execute(this.currentIndex);
		}
	}
}


export interface AppProps extends JSX.HTMLAttributes<HTMLDivElement> {
	options: SelectContainer;
	children?: undefined;
}
export class ListSelect extends BasicComponent<AppProps> {

	public val = 123;
	private uuid = Math.random();
	private options: SelectContainer;

	public override init() {
		this.options = this.props.options;
		(this.props as any).options = undefined;

		this.options.onChanged.addCallback(this.update, this);
	}

	public override willReceiveProps(nextProps: AppProps) {
		if (this.options !== nextProps.options) {
			this.options = nextProps.options;
			if (this.options !== undefined) {
				this.options.onChanged.removeCallback(this.update, this);
			}
			nextProps.options = undefined as any;

			this.options.onChanged.addCallback(this.update, this);
			this.update();
		}
	}

	public render() {
		return <div class="ListSelect" {...this.props}>
			{this.options.map((option, index) => {
				if (typeof (option) === "string") {
					return <ListElement options={this.options} name={option} index={index}></ListElement>;
				} else {
					return <ListElement options={this.options} name={option.label} index={index} labelClass={option.labelClass}></ListElement>;
				}
			})}
		</div>;
	}
}
