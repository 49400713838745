import { Utils } from "@h4x/common";

export const options: {
	debug?: boolean;
	verbose?: boolean;
} = {};

// options.verbose = true;

function TSX_DEBUG(...args: any[]) {
	if (options.verbose === true) {
		console.warn(`[H4X-TSX-DEBUG] %c${args.shift()}`, "color: #00D; font-weight: 800;", ...args); // `
	}
}

function TSX_ERROR(...args: any[]) {
	console.warn(`[H4X-TSX-ERROR] %c${args.shift()}`, "color: #D00; font-weight: 800;", ...args); // `
}

function used(x: any) { }

export class VNode<P = any> {
	public type: (new () => Component<P>) | string;
	public children: ComponentChild[];
	public props: P;
	public key?: string | number;
}

export type ComponentChild = VNode | string | number | null;
export type ComponentChildren = ComponentChild[] | ComponentChild;

export interface Attributes<T> {
	key?: string | number | any;
	ref?: (instance: T) => void;
}


export type ComponentProps<P, RefType = any> = Readonly<Attributes<RefType> & P>;


export function h<P>(node: (new () => Component<P>), props: Attributes<any> & P | null, ...children: ComponentChildren[]): VNode<P>;
export function h(node: string, props: JSX.HTMLAttributes<any> & JSX.SVGAttributes & Record<string, any> | null, ...children: ComponentChildren[]): VNode;
export function h<P>(type: (new () => Component<P>) | string, props: any, ...children: ComponentChildren[]) {
	let vnodes: ComponentChild[] = [];

	parseChildren(typeof type !== "function", children, vnodes);

	let p = new VNode();
	p.type = type;
	p.children = vnodes;
	if (props !== null) {
		p.props = props;
		p.key = props.key;
	}

	return p;
}

function parseChildren(simple: boolean, children: ComponentChildren[], output: ComponentChild[], lastSimple = false) {
	for (let i = 0; i < children.length; i++) {
		let child = children[i];
		if (Array.isArray(child)) {
			lastSimple = parseChildren(simple, child, output, lastSimple);
		} else {
			if (typeof child === "boolean") { child = null; }
			if (simple) {
				if (lastSimple) {
					if (child === undefined || child === null) {
						continue;
					} else if (typeof child === "number") {
						output[output.length - 1] += "" + child;
					} else if (typeof child === "string") {
						output[output.length - 1] += child;
					} else {
						lastSimple = false;
						output.push(child as ComponentChild);
					}
				} else {
					output.push(child as ComponentChild);
				}
			} else {
				output.push(child as ComponentChild);
			}
		}
	}
	return lastSimple;
}

export function renderClass(vnode: (new () => Component<any>), parent: Element | Document): Element | undefined {
	let rendered = renderNode(h(vnode, null, []));
	if (rendered === undefined) {
		throw ("Couldn't render, got undefined!");
	}
	let element: Element = (rendered as any).node;
	if (element !== undefined) {
		parent.appendChild(element);
	}

	if (rendered instanceof VComponent) {
		rendered.onAttached();
	}

	(global as any).app = rendered;

	return element;
}

const SVGElements: { [K: string]: boolean | undefined; } = {
	svg: true, g: true, foreignObject: true,
	path: true, text: true, textPath: true,
	circle: true, ellipse: true, line: true, polygon: true, polyline: true, rect: true
};

export function render(vnode: ComponentChild, parent: Element | Document): Element | undefined {
	let rendered = renderNode(vnode);
	if (rendered === undefined) {
		throw ("Couldn't render, got undefined!");
	}
	let element: Element = (rendered as any).node;
	if (element !== undefined) {
		parent.appendChild(element);
	}

	if (rendered instanceof VComponent) {
		rendered.onAttached();
	}

	(global as any).app = rendered;

	return element;
}

function renderNode(vnode: VNode): VDomElement | VComponent<any>;
function renderNode(vnode: ComponentChild): VDomNode | undefined;
function renderNode(vnode: ComponentChild): VDomNode | VComponent<any> | undefined {
	if (vnode instanceof VNode) {
		let type = vnode.type;
		if (typeof (type) === "string") {
			let vdom = new VDomElement();

			if (SVGElements[type] === true) {
				vdom.init(document.createElementNS("http://www.w3.org/2000/svg", type), vnode.key);
			} else {
				vdom.init(document.createElement(type), vnode.key);
			}
			vdom.applyAttributes(vnode.props);

			for (const child of vnode.children) {
				if (child instanceof VNode) {
					let rendered = renderNode(child);
					vdom.addChild(rendered);
				} else {
					let rendered = renderNode(child);
					if (rendered !== undefined) {
						vdom.addChild(rendered);
					}
				}
			}

			return vdom;
		} else {
			return new VComponent(type, vnode.props, vnode.children);
		}
	} else if (typeof (vnode) === "string") {
		return new VDomText("" + vnode);
	} else if (typeof (vnode) === "number") {
		return new VDomText("" + vnode);
	} else if (vnode === undefined || vnode === null) { // FIXME remove null! throw?
		return undefined;
	} else if (Array.isArray(vnode)) {
		return undefined;
	}

	throw new Error("Invalid node type!");
}

abstract class VDom {
	public readonly key?: string | number;
	public isActive = true;

	public isCompatible(vnode: ComponentChild): boolean {
		throw new Error("Invalid isCompatible!");
	}

	public abstract update(vnode: ComponentChild): void;
}

abstract class VDomNode extends VDom {

	protected nodeName: string;
	public node: Node;
	public children: (VDomNode | VComponent<any>)[] = [];
	public mappedChildren = new Map<string | number, (VDomNode | VComponent<any>)>();
	public isPersistent = false;


	get typeName() {
		return "VDomNode";
	}

	constructor() {
		super();
	}

	public init(node: Node, key: string | number | undefined) {
		this.node = node;
		(this as any).key = key;

		this.nodeName = this.node.nodeName.toLowerCase();
		return this;
	}

	public abstract destroy(): void;

	public addChild(vdom: VDomNode | VComponent<any>) {
		this.children.push(vdom);
		if (vdom.key !== undefined) {
			this.mappedChildren.set(vdom.key, vdom);
		}
		this.node.appendChild(vdom.node);

		if (vdom instanceof VComponent) {
			vdom.onAttached();
		}
	}

	public updateChildren(vnode: VNode) {
		if (this.nodeName === "raw") {
			if (this.children.length > 0) {
				TSX_ERROR("[VDOM] Raw nodes shouldn't have children defined in VDOM!");
			} else {
				return;
			}
		}

		for (const child of this.children) {
			child.isActive = false;
		}

		let newChildren: (VDomNode | VComponent<any>)[] = [];

		for (const vChild of vnode.children) {
			let current;

			if (vChild instanceof VNode && vChild.key !== undefined) {
				current = this.mappedChildren.get(vChild.key);
				if (current !== undefined && current.isCompatible(vChild) === false) {
					TSX_ERROR(`[VNode] Incompatible component with key ${JSON.stringify(vChild.key)}, got ${current.typeName}, expected ${(vChild.type as any).name}!`);// `
					let index = this.children.indexOf(current);
					if (index > -1) { this.children.splice(index, 1); this.node.removeChild(current.node); }
					current.destroy();
					current = undefined;
					this.mappedChildren.delete(vChild.key);
				}
			} else {
				let c = 0;
				// TODO optimize start
				while (c < this.children.length) {
					let child = this.children[c++];
					if (child.key !== undefined || child.isActive === true || child.isCompatible(vChild) === false) {
						continue;
					}

					current = child;
					break;
				}
			}


			if (current) {
				newChildren.push(current);
				current.isActive = true; // mark it as active
				(current as VDom).update(vChild);
			} else {
				let newChild = renderNode(vChild);
				if (newChild) {
					newChildren.push(newChild);
				}
			}
		}

		for (let c = 0; c < this.children.length; c++) {
			const child = this.children[c];
			if (child.isActive === false) {
				if (child.isPersistent === false) {
					if (child.key !== undefined) {
						this.mappedChildren.delete(child.key);
					}
					this.node.removeChild(child.node);
					this.children[c] = undefined!;
					child.destroy();
				} else {
					this.node.removeChild(child.node);
					if (child instanceof VComponent) {
						child.onDetached();
					}
					this.children[c] = undefined!;
				}
			}
		}

		let i = 0;
		let oldChildren = this.children;
		this.children = newChildren;
		let lastChild;
		for (const newChild of this.children) {
			let oldChild;
			while (oldChild === undefined && i < oldChildren.length) {
				oldChild = oldChildren[i++];
			}
			let existing = false;
			if (oldChild) {
				if (oldChild !== newChild) {
					this.node.insertBefore(newChild.node, oldChild.node);
					for (let c = i; c < oldChildren.length; c++) {
						if (oldChildren[c] === newChild) {
							oldChildren[c] = undefined!;
							existing = true;
							break;
						}
					}
					i--;
				} else {
					existing = true;
				}
			} else {
				this.node.appendChild(newChild.node);
			}

			if (existing === false) {

				if (newChild.key !== undefined) {
					if (options.debug) {
						let current = this.mappedChildren.get(newChild.key);
						if (current !== undefined && current.isPersistent === false) {
							TSX_ERROR(`[VDOM] Key ${JSON.stringify(newChild.key)} already exists while adding a new key!`);// `
						}
					}
					this.mappedChildren.set(newChild.key, newChild);
				}
				if (newChild instanceof VComponent) {
					newChild.onAttached();
				}
			}

			lastChild = newChild;
		}

		if (options.debug) {
			let domNodes = Array.from(this.node.childNodes.values());
			let currentNodes = this.children.map(x => x.node);
			if (Utils.equals(domNodes, currentNodes) === false) {
				TSX_ERROR("[VDOM] Update failed, child nodes don't match!");
			}
		}
	}

	public setText(text: string) { throw new Error("Can't set text on non VDomText!"); }
}

const attributeHandlers: { [K: string]: (node: Element, old: any, target: any) => void } = {
	class: (node, _old, target) => {
		if (Array.isArray(target)) {
			if (target.length > 0) {
				node.setAttribute("class", target.join(" "));
			} else {
				node.removeAttribute("class");
			}
		} else if (typeof (target) === "object") {
			let classes = [];
			for (const name in target) {
				if (target.hasOwnProperty(name)) {
					if (target[name] === true) {
						classes.push(name);
					}
				}
			}
			if (classes.length > 0) {
				node.setAttribute("class", classes.join(" "));
			} else {
				node.removeAttribute("class");
			}
		} else if (typeof (target) === "string") {
			if (target.length > 0) {
				node.setAttribute("class", target);
			} else {
				node.removeAttribute("class");
			}
		} else if (target === undefined || target === null) {
			node.removeAttribute("class");
		} else {
			TSX_DEBUG("Got invalid attribute for style, of type " + (typeof (target)) + ", and value " + target + "");
		}
	},
	ref: (node, old, target) => { if (old !== target) { if (old) { old(null); } if (target) { target(node); } } },
	style: (node, old, target) => {
		let style = (node as any).style;
		if (style instanceof CSSStyleDeclaration) {
			if (!target || typeof target === "string") {
				style.cssText = target || "";
			} else if (typeof old === "string") {
				style.cssText = "";
			}
			if (target && typeof target === "object") {
				if (typeof old !== "string") {
					for (let i in old) {
						if (!(i in target)) { (style as any)[i] = ""; }
					}
				}
				for (let i in target) {
					if (target.hasOwnProperty(i)) {
						if (i === "flex") {
							style[i] = target[i];
						} else {
							(style as any)[i] = typeof target[i] === "number" ? (target[i] + "px") : target[i];
						}
					}
				}
			}
		}
	},
	dangerouslySetInnerHTML: (node, _old, target) => { if (target) { node.innerHTML = target.__html || ""; } },

	children: () => { /* noop */ },
	innerHTML: () => { /* noop */ },
};

attributeHandlers.className = attributeHandlers.class;

class VDomElement extends VDomNode {

	public override readonly node: Element;

	private props: any = {};

	public destroy() {
		for (const child of this.children) { child.destroy(); }
		this.applyAttributes(undefined);
		(this as any).node = undefined!;
		this.props = undefined!;
	}

	override get typeName() {
		return "VDomElement->" + this.nodeName;
	}

	public applyAttributes(target: any): any {
		let dom = this.node as Element;

		if (!target) {
			// remove all props
			for (let name in this.props) {
				if (this.props.hasOwnProperty(name)) {
					this.applyAttribute(dom, name, this.props[name], undefined);
				}
			}

			target = {};
		} else {
			// remove missing props
			for (let name in this.props) {
				if (target[name] == null && this.props[name] != null) {
					this.applyAttribute(dom, name, this.props[name], undefined);
				}
			}

			// add new props and update existing ones
			for (let name in target) {
				if (target.hasOwnProperty(name)) {
					let old = this.props[name];

					// get value/checked directly from the dom
					if (name === "value" || name === "checked") {
						old = (dom as HTMLInputElement)[name];
					}

					if (target[name] !== old) {
						this.applyAttribute(dom, name, old, target[name]);
					}
				}
			}
		}

		this.props = target;
	}

	private listeners: any = {};
	get eventHandler() {
		let self = this;
		const fnBound = function (this: Node, e: Event) { self.listeners[e.type](e, this); };
		Object.defineProperty(this, "eventHandler", { value: fnBound, configurable: true, writable: true });
		return fnBound;
	}

	private applyAttribute(node: Element, name: string, old: any, target: any) {
		let handler = attributeHandlers[name];

		if (handler !== undefined) {
			return handler(node, old, target);
		}

		if (name.startsWith("on")) {
			name = name.toLowerCase().substring(2);
			if (target) {
				if (!old) { node.addEventListener(name, this.eventHandler, false); }
			} else {
				node.removeEventListener(name, this.eventHandler, false);
			}
			this.listeners[name] = target;
		} else if (name.startsWith("capture")) {
			name = name.toLowerCase().substring(7);
			if (target) {
				if (!old) { node.addEventListener(name, this.eventHandler, true); }
			} else {
				node.removeEventListener(name, this.eventHandler, true);
			}
			this.listeners[name] = target;
		} else if (node.namespaceURI !== "http://www.w3.org/2000/svg" && name !== "list" && name !== "type" && name in node) {
			try { (node as any)[name] = target == null ? "" : target; } catch (e) { }
			if (target == null || target === false) { node.removeAttribute(name); }
		} else {
			if (target == null || target === false) {
				node.removeAttribute(name);
			} else if (typeof target !== "function" && typeof target !== "object") {
				node.setAttribute(name, target);
			}
		}
	}

	public update(vnode: VNode) {
		if (vnode instanceof VNode === false) { throw new Error("Invalid node type!"); }

		let type = vnode.type;
		if (typeof (type) === "string") {
			(this as any as VDomElement).applyAttributes(vnode.props);
			this.updateChildren(vnode);
		} else {
			throw new Error("Invalid node type!");
		}
	}

	public override isCompatible(vnode: ComponentChild) {
		return vnode instanceof VNode && typeof vnode.type === "string" && vnode.type.toLowerCase() === this.nodeName;
	}

	public override setText(text: string) { throw new Error("Can't set text, invalid dom type!"); }
}


class VDomText extends VDomNode {
	constructor(text: string) {
		super();
		this.currentText = text;
		this.init(document.createTextNode(text), undefined);
	}

	public destroy() {
		this.node = undefined!;
	}

	override get typeName() {
		return "VDomText";
	}

	private currentText = "";
	public override setText(text: string) {
		if (this.currentText !== text) {
			this.currentText = text;
			(<Text>this.node).nodeValue = text;
		}
	}

	public update(vnode: string | number) {
		if (typeof (vnode) === "string") {
			this.setText("" + vnode);
		} else if (typeof (vnode) === "number") {
			this.setText("" + vnode);
		} else {
			throw new Error("Invalid node type!");
		}
	}

	public override isCompatible(vnode: ComponentChild) {
		return typeof vnode === "string" || typeof vnode === "number";
	}
}

class VComponent<P> extends VDom {
	public instance: Component<P>;
	public vdom: VDomElement | VComponent<any>;

	public isPersistent = false;

	get node(): Node {
		return this.vdom.node;
	}

	constructor(type: new () => Component<P>, props: ComponentProps<P, any>, children: ComponentChild[]) {
		super();

		if (type.prototype === undefined || type.prototype.render === undefined) {
			throw new Error("Component need to have a render function!");
		}
		let instance: Component<P> = new type();
		if (!(type.prototype instanceof Component)) {
			throw new Error("Components must extend the Component class!");
		}

		this.instance = instance;
		instance[$base] = this;
		instance[$init](props, children);
		this.vdom = renderNode(instance.render());

		if (props !== undefined) {
			if ((props as any).key) {
				(this as any).key = (props as any).key;
			}
			if ((props as any).persistent) {
				this.isPersistent = true;
			}
			if (props.ref) {
				props.ref(instance);
			}
		}
	}

	public destroy() {
		this.vdom.destroy();
		this.onDetached();
		this.instance[$destroy]();
		this.instance[$base] = undefined!;
	}

	public onAttached() {
		this.instance[$attached]();
	}
	public onDetached() {
		this.instance[$detached]();
	}

	get typeName() {
		return "VDomComponent->" + this.instance.constructor.name;
	}

	public componentUpdate(vnode: VNode) {
		if (this.vdom.isCompatible(vnode)) {
			TSX_DEBUG("Compatible", this, vnode);
			this.vdom.update(vnode);
		} else {
			// rerender
			TSX_DEBUG("Incompatible", this, vnode);
			let oldNode = this.node;
			this.vdom = renderNode(vnode);
			// debugger;
			oldNode.parentNode!.replaceChild(this.node, oldNode);
		}
	}


	public update(vnode: VNode) {
		TSX_DEBUG("VComponent update");
		if (vnode instanceof VNode === false) { throw new Error("Invalid node type!"); }

		let type = vnode.type;
		if (typeof (type) === "string") {
			throw new Error("Invalid node type!");
		} else {
			let instance = this.instance;
			if (instance === undefined) {
				throw new Error("Missing component instance!");
			}
			instance[$updateProps](vnode.props, vnode.children);
		}
	}

	public override isCompatible(vnode: ComponentChild) {
		if (vnode instanceof VNode && typeof vnode.type === "function") {
			return this.instance.constructor === vnode.type;
		}
		return false;
	}
}

export const $base = Symbol.for("$TSX_base");

const $updateProps = Symbol.for("$TSX_internal_UpdateProps");
const $update = Symbol.for("$TSX_internal_Update");
const $init = Symbol.for("$TSX_internal_Init");
const $attached = Symbol.for("$TSX_internal_Attached");
const $detached = Symbol.for("$TSX_internal_Detached");
const $destroy = Symbol.for("$TSX_internal_Destroy");

let updateSchedule = new Set<Component<any>>();

export namespace TSX {
	let interval: NodeJS.Timer | undefined;

	export function configureInterval(fps: number) {
		if (interval !== undefined) { clearInterval(interval); }
		interval = setInterval(() => {
			let updating = updateSchedule;
			updateSchedule = new Set<Component<any>>();
			updating.forEach((component) => {
				component[$update]();
			});
		}, 1000 / fps); // 20 fps
	}

	configureInterval(20);
}

export abstract class Component<P> {
	public static readonly Children = Symbol.for("$TSX_Children");

	protected UUID = Math.random().toFixed(16).substring(2);

	public [$base]: VComponent<P>;
	private updateScheduled = false;
	private internalUpdating = false;
	private internalDestroyed = false;

	public props: ComponentProps<P, this>; // TODO ref returns an instance of the Component
	public children: ComponentChild[];

	constructor() {
		TSX_DEBUG("created " + this.constructor.name);
	}

	public abstract render(): VNode<this>;

	public [$init](props: ComponentProps<P, any>, children: ComponentChild[]) {
		if (props === undefined) { props = {} as any; }
		this.props = props;
		this.children = children;
		if ((this as any).__init) {
			(this as any).__init();
		}
		this.init();
	}
	protected init() { }

	public [$destroy]() {
		this.internalDestroyed = true;
		if ((this as any).__destroy) {
			(this as any).__destroy();
		}
		this.destroy();
	}
	protected destroy() { }

	public onAttached() { }
	public onDetached() { }

	public [$attached]() { this.onAttached(); TSX_DEBUG("Attached " + this.UUID); }
	public [$detached]() { this.onDetached(); TSX_DEBUG("Detached " + this.UUID); }

	public [$update]() {
		if (this[$base] === undefined) {
			// ignore the component could have been deleted by the parent
		} else {
			this.preUpdate();
			this[$base].componentUpdate(this.render());
			this.postUpdate();
		}
	}

	protected update() {
		if (this[$base] === undefined) {
			if (this.internalDestroyed) {
				TSX_ERROR("Component dom is undefined when trying to schedule an update, the component was been already destroyed!");
			} else {
				TSX_ERROR("Component dom is undefined when trying to schedule an update!");
			}
		}

		if (this.internalUpdating) {
			this.updateScheduled = true;
		} else {
			updateSchedule.add(this);
		}
	}

	protected preUpdate() { }

	protected postUpdate() { }

	protected receivedProps(props: ComponentProps<P, this>, children: ComponentChild[]) {
		TSX_DEBUG("receivedProps " + this.constructor.name);
		this.willReceiveProps(props, children);
		this.props = props;
		this.children = children;
		// this.updateScheduled = true;
	}

	protected willReceiveProps(props: ComponentProps<P, this>, children: ComponentChild[]) { }

	public [$updateProps](props: ComponentProps<P, this>, children: ComponentChild[]) {
		if (props === undefined) { props = {} as any; }
		TSX_DEBUG("internalUpdateProps " + this.constructor.name);
		this.internalUpdating = true;

		this.receivedProps(props, children);

		this.internalUpdating = false;
		if (this.updateScheduled) {
			this[$update]();
			this.updateScheduled = false;
		}
	}
}

export namespace Component { }
