import { h } from "../../../TSX/TSX";
import { BasicComponent } from "../../BasicComponent";
import { BasicEvent, Utils } from "@h4x/common";
import { ValidatorOutput, Validator } from "../CEValidator";
import { CEUtils } from "../CEUtils";

export class BooleanContainer extends BasicComponent<{
	schema: any;
	data: any;
	key: any;
	onUpdate: BasicEvent<any>;
	value?: undefined;
	onInput?: undefined;
}> {
	public override init() {
		this.validate();
	}

	public override willReceiveProps() {
		this.update();
	}

	public get(): boolean {
		return this.props.data[this.props.key];
	}

	public set(value: boolean) {
		this.props.data[this.props.key] = value;
	}

	@BasicComponent.bind()
	private onInput(event: any) {
		let value = event.target.checked === true;
		this.set(value);
		this.props.onUpdate.execute(value);
		this.validate();
	}

	private validatorData: ValidatorOutput;

	@BasicComponent.bind()
	private validate() {
		let oldData = this.validatorData;
		this.validatorData = Validator.boolean(this.props.schema, this.get(), this.props.data, this.props.key);

		if (Utils.equals(oldData, this.validatorData) === false) {
			console.log("validate...");
			this.update();
		}
	}

	public render() {
		let extra = CEUtils.parseInfo(this.validatorData);

		return <div style={{ "display": "inline-block" }}>
			<input class="InputContainer BooleanContainer" type="checkbox" checked={this.get()} onInput={this.onInput} />
			{extra}
		</div>;
	}
}