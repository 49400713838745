import { h, VNode, Component, ComponentChildren, $base } from "../TSX/TSX";
import { BasicComponent } from "./BasicComponent";
import { Overlay } from "../TSX/Overlay";

import "./Tooltip.scss";

export class Tooltip extends BasicComponent<{
	[Component.Children]?: ComponentChildren | object;
} & JSX.HTMLAttributes<HTMLDivElement>> {
	public render() {
		return (
			<div class="Tooltip" {...this.props}>{this.children}</div>
		);
	}
}

export class TooltipProvider extends BasicComponent<{
	renderTooltip: () => VNode
} & JSX.HTMLAttributes<HTMLDivElement>> {

	protected override willReceiveProps() {
		this.update();
	}

	private tooltip: { vnode: VNode; instance: BasicComponent; };

	@BasicComponent.bind()
	private onMouseEnter(/*event: MouseEvent*/) {
		let container = (this[$base].node as HTMLDivElement);
		let rect = container.getClientRects();

		this.tooltip = Overlay.addFloating(<Tooltip style={{
			top: rect[0].top,
			left: rect[0].right + 5,
			position: "fixed"
		}}>{this.props.renderTooltip()}</Tooltip>);
	}

	@BasicComponent.bind()
	private onMouseLeave() {
		if (this.tooltip) {
			Overlay.removeFloating(this.tooltip.instance);
		}
	}

	public render() {
		return (
			<div class="TooltipProvider" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} {...this.props}>{this.children}</div>
		);
	}
}
