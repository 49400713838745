import { BufferError } from "./BufferError";
import { internalBytes, internalFloatArray, internalDoubleArray, AbstractBuffer, RawInternalBuffer, InternalBuffer } from "./AbstractBuffer";

export class WrappedBuffer extends AbstractBuffer {

	private _buffer: ArrayBuffer;
	public readonly buffer: Uint8Array;
	private bufferS: Int8Array;
	private _readPos: number;
	private _writePos: number;
	private _limit: number;

	constructor(buffer: ArrayBuffer, private readonly offset: number, length: number) {
		super();
		if (!(buffer instanceof ArrayBuffer)) {
			throw new BufferError("Couldn't create WrappedBuffer, invalid constructor argument " + typeof (buffer) + ", expected ArrayBuffer!");
		}
		offset = offset | 0;
		length = length | 0;

		this._buffer = buffer;

		this.buffer = new Uint8Array(buffer, offset, length);
		this.bufferS = new Int8Array(buffer, offset, length);
		this._readPos = 0;
		this._writePos = 0;

		this._limit = this.buffer.byteLength;
	}

	public getInternalBuffers() {
		return [this as any as InternalBuffer];
	}

	public static fromBuffer(buffer: Uint8Array) {
		if (!(buffer instanceof Uint8Array)) {
			throw new BufferError("Couldn't create WrappedBuffer(fromBuffer), invalid constructor argument " + typeof (buffer) + ", expected Uint8Array!");
		}
		return new WrappedBuffer(buffer.buffer, buffer.byteOffset, buffer.byteLength);
	}

	public static fromArrayBuffer(buffer: ArrayBuffer) {
		if (!(buffer instanceof ArrayBuffer)) {
			throw new BufferError("Couldn't create WrappedBuffer(fromArrayBuffer), invalid constructor argument " + typeof (buffer) + ", expected ArrayBuffer!");
		}
		return new WrappedBuffer(buffer, 0, buffer.byteLength);
	}

	public static allocate(size: number) {
		size = size | 0;
		return new WrappedBuffer(new ArrayBuffer(size), 0, size);
	}

	public subBuffer(start: number, length: number) {
		return new WrappedBuffer(this._buffer, this.offset + start, length);
	}

	set writePos(value: number) {
		if (value > this._limit) {
			throw new BufferError("[WrappedBuffer::writePos] Can't set writePos above buffer's capacity!");
		}
		if (value < 0) {
			throw new BufferError("[WrappedBuffer::writePos] Can't set writePos below 0!");
		}
		this._writePos = value;
	}

	get writePos(): number {
		return this._writePos;
	}

	set readPos(value: number) {
		if (value > this._limit) {
			throw new BufferError("[WrappedBuffer::readPos] Can't set readPos above buffer's capacity!");
		}
		if (value < 0) {
			throw new BufferError("[WrappedBuffer::readPos] Can't set readPos below 0!");
		}
		this._readPos = value;
	}

	get readPos(): number {
		return this._readPos;
	}

	set limit(value: number) {
		if (value > this.buffer.byteLength) {
			throw new BufferError("[WrappedBuffer::limit] Can't set limit above buffer's capacity!");
		}
		if (value < 0) {
			throw new BufferError("[WrappedBuffer::limit] Can't set limit below 0!");
		}
		this._limit = value;
	}

	get limit(): number {
		return this._limit;
	}

	public resetLimit() {
		this.limit = this.buffer.byteLength;
	}

	public restart() {
		this._readPos = 0;
		this._writePos = 0;
	}

	public writeBool(value: boolean) {
		if (this._limit < (this._writePos + 1)) {
			throw new BufferError("[WrappedBuffer::writeBool] buffer access out of bounds!");
		}
		this.buffer[this._writePos++] = (value ? 1 : -1);
	}

	public readBool(): boolean {
		if (this._limit < (this._readPos + 1)) {
			throw new BufferError("[WrappedBuffer::readBool] buffer access out of bounds!");
		}
		return (this.buffer[this._readPos++] === 1);
	}

	public writeUInt8(value: number) {
		if (this._limit < (this._writePos + 1)) {
			throw new BufferError("[WrappedBuffer::writeUByte] buffer access out of bounds!");
		}
		this.buffer[this._writePos++] = (value | 0);
	}

	public readUInt8(): number {
		if (this._limit < (this._readPos + 1)) {
			throw new BufferError("[WrappedBuffer::readUByte] buffer access out of bounds!");
		}
		return this.buffer[this._readPos++];
	}

	public writeInt8(value: number) {
		if (this._limit < (this._writePos + 1)) {
			throw new BufferError("[WrappedBuffer::writeByte] buffer access out of bounds!");
		}
		this.bufferS[this._writePos++] = (value | 0);
	}

	public readInt8(): number {
		if (this._limit < (this._readPos + 1)) {
			throw new BufferError("[WrappedBuffer::readByte] buffer access out of bounds!");
		}
		return this.bufferS[this._readPos++];
	}

	public writeInt16(value: number) {
		if (this._limit < (this._writePos + 2)) {
			throw new BufferError("[WrappedBuffer::writeInt16] buffer access out of bounds!");
		}
		value = value | 0;
		this.bufferS[this._writePos + 0] = (value >> 8);
		this.buffer[this._writePos + 1] = value;
		this._writePos += 2;
	}

	public readInt16(): number {
		if (this._limit < (this._readPos + 2)) {
			throw new BufferError("[WrappedBuffer::readInt16] buffer access out of bounds!");
		}
		const out = (this.bufferS[this._readPos + 0] << 8) | this.buffer[this._readPos + 1];
		this._readPos += 2;
		return out;
	}

	public writeUInt16(value: number) {
		if (this._limit < (this._writePos + 2)) {
			throw new BufferError("[WrappedBuffer::writeUInt16] buffer access out of bounds!");
		}
		value = value | 0;
		this.buffer[this._writePos + 0] = (value >> 8);
		this.buffer[this._writePos + 1] = value;
		this._writePos += 2;
	}

	public readUInt16(): number {
		if (this._limit < (this._readPos + 2)) {
			throw new BufferError("[WrappedBuffer::readUInt16] buffer access out of bounds!");
		}
		const out = (this.buffer[this._readPos + 0] << 8) | this.buffer[this._readPos + 1];
		this._readPos += 2;
		return out;
	}

	public writeInt24(value: number) {
		if (this._limit < (this._writePos + 3)) {
			throw new BufferError("[WrappedBuffer::writeInt24] buffer access out of bounds!");
		}
		value = value | 0;
		this.bufferS[this._writePos + 0] = (value >> 16);
		this.buffer[this._writePos + 1] = (value >> 8);
		this.buffer[this._writePos + 2] = value;
		this._writePos += 3;
	}

	public readInt24(): number {
		if (this._limit < (this._readPos + 3)) {
			throw new BufferError("[WrappedBuffer::readInt24] buffer access out of bounds!");
		}
		const out = (this.bufferS[this._readPos + 0] << 16) | (this.buffer[this._readPos + 1] << 8) | this.buffer[this._readPos + 2];
		this._readPos += 3;
		return out;
	}

	public writeUInt24(value: number) {
		if (this._limit < (this._writePos + 3)) {
			throw new BufferError("[WrappedBuffer::writeUInt24] buffer access out of bounds!");
		}
		value = value >>> 0;
		this.buffer[this._writePos + 0] = (value >> 16);
		this.buffer[this._writePos + 1] = (value >> 8);
		this.buffer[this._writePos + 2] = value;
		this._writePos += 3;
	}

	public readUInt24(): number {
		if (this._limit < (this._readPos + 3)) {
			throw new BufferError("[WrappedBuffer::readUInt24] buffer access out of bounds!");
		}
		const out = (this.buffer[this._readPos + 0] << 16) | (this.buffer[this._readPos + 1] << 8) | this.buffer[this._readPos + 2];
		this._readPos += 3;
		return out >>> 0;
	}

	public writeInt32(value: number) {
		if (this._limit < (this._writePos + 4)) {
			throw new BufferError("[WrappedBuffer::writeInt32] buffer access out of bounds!");
		}
		value = value | 0;
		this.buffer[this._writePos + 0] = (value >> 24);
		this.buffer[this._writePos + 1] = (value >> 16);
		this.buffer[this._writePos + 2] = (value >> 8);
		this.buffer[this._writePos + 3] = value;
		this._writePos += 4;
	}

	public readInt32(): number {
		if (this._limit < (this._readPos + 4)) {
			throw new BufferError("[WrappedBuffer::readInt32] buffer access out of bounds!");
		}
		const out = (this.buffer[this._readPos + 0] << 24) | (this.buffer[this._readPos + 1] << 16) | (this.buffer[this._readPos + 2] << 8) | this.buffer[this._readPos + 3];
		this._readPos += 4;
		return out;
	}

	public writeUInt32(value: number) {
		if (this._limit < (this._writePos + 4)) {
			throw new BufferError("[WrappedBuffer::writeInt32] buffer access out of bounds!");
		}
		value = value >>> 0;
		this.buffer[this._writePos + 0] = (value >> 24);
		this.buffer[this._writePos + 1] = (value >> 16);
		this.buffer[this._writePos + 2] = (value >> 8);
		this.buffer[this._writePos + 3] = value;
		this._writePos += 4;
	}

	public readUInt32(): number {
		if (this._limit < (this._readPos + 4)) {
			throw new BufferError("[WrappedBuffer::readInt32] buffer access out of bounds!");
		}
		const out = (this.buffer[this._readPos + 0] << 24) | (this.buffer[this._readPos + 1] << 16) | (this.buffer[this._readPos + 2] << 8) | this.buffer[this._readPos + 3];
		this._readPos += 4;
		return out >>> 0;
	}

	public writeFloat32(value: number) {
		if (this._limit < (this._writePos + 4)) {
			throw new BufferError("[WrappedBuffer::writeFloat32] buffer access out of bounds!");
		}
		internalFloatArray[0] = +value;
		this.buffer[this._writePos + 0] = (internalBytes[0] | 0);
		this.buffer[this._writePos + 1] = (internalBytes[1] | 0);
		this.buffer[this._writePos + 2] = (internalBytes[2] | 0);
		this.buffer[this._writePos + 3] = (internalBytes[3] | 0);
		this._writePos += 4;
	}

	public readFloat32(): number {
		if (this._limit < (this._readPos + 4)) {
			throw new BufferError("[WrappedBuffer::readFloat32] buffer access out of bounds!");
		}
		internalBytes[0] = this.buffer[this._readPos + 0];
		internalBytes[1] = this.buffer[this._readPos + 1];
		internalBytes[2] = this.buffer[this._readPos + 2];
		internalBytes[3] = this.buffer[this._readPos + 3];
		this._readPos += 4;
		return internalFloatArray[0];
	}

	public writeFloat64(value: number) {
		if (this._limit < (this._writePos + 8)) {
			throw new BufferError("[WrappedBuffer::writeFloat64] buffer access out of bounds!");
		}
		internalDoubleArray[0] = +value;
		this.buffer[this._writePos + 0] = (internalBytes[0] | 0);
		this.buffer[this._writePos + 1] = (internalBytes[1] | 0);
		this.buffer[this._writePos + 2] = (internalBytes[2] | 0);
		this.buffer[this._writePos + 3] = (internalBytes[3] | 0);
		this.buffer[this._writePos + 4] = (internalBytes[4] | 0);
		this.buffer[this._writePos + 5] = (internalBytes[5] | 0);
		this.buffer[this._writePos + 6] = (internalBytes[6] | 0);
		this.buffer[this._writePos + 7] = (internalBytes[7] | 0);
		this._writePos += 8;
	}

	public readFloat64(): number {
		if (this._limit < (this._readPos + 8)) {
			throw new BufferError("[WrappedBuffer::readFloat64] buffer access out of bounds!");
		}
		internalBytes[0] = this.buffer[this._readPos + 0];
		internalBytes[1] = this.buffer[this._readPos + 1];
		internalBytes[2] = this.buffer[this._readPos + 2];
		internalBytes[3] = this.buffer[this._readPos + 3];
		internalBytes[4] = this.buffer[this._readPos + 4];
		internalBytes[5] = this.buffer[this._readPos + 5];
		internalBytes[6] = this.buffer[this._readPos + 6];
		internalBytes[7] = this.buffer[this._readPos + 7];
		this._readPos += 8;
		return internalDoubleArray[0];
	}

	public setInt32(offset: number, value: number) {
		if (this._limit < (offset + 4)) {
			throw new BufferError("[WrappedBuffer::setInt32] buffer access out of bounds!");
		}
		offset = offset | 0;
		value = value | 0;
		this.buffer[offset] = (value >> 24);
		this.buffer[offset + 1] = (value >> 16);
		this.buffer[offset + 2] = (value >> 8);
		this.buffer[offset + 3] = value;
	}

	public writeBuffer(buffer: Uint8Array) {
		this.buffer.set(buffer, this._writePos);
		this._writePos += buffer.byteLength;
	}

	public readBuffer(size: number) {
		let start = this._readPos;
		let buffer = this.buffer.slice(start, start + size);
		this._readPos += buffer.byteLength;
		return buffer;
	}

	public toBuffer() {
		return this.buffer.slice(0, this.writePos);
	}

	/*****************************************************************
						LE - little endian
	*****************************************************************/

	public writeInt16LE(value: number) {
		if (this._limit < (this._writePos + 2)) {
			throw new BufferError("[WrappedBuffer::writeInt16] buffer access out of bounds!");
		}
		value = value | 0;
		this.bufferS[this._writePos + 1] = (value >> 8);
		this.buffer[this._writePos + 0] = value;
		this._writePos += 2;
	}

	public readInt16LE(): number {
		if (this._limit < (this._readPos + 2)) {
			throw new BufferError("[WrappedBuffer::readInt16] buffer access out of bounds!");
		}
		const out = (this.bufferS[this._readPos + 1] << 8) | this.buffer[this._readPos + 0];
		this._readPos += 2;
		return out;
	}

	public writeUInt16LE(value: number) {
		if (this._limit < (this._writePos + 2)) {
			throw new BufferError("[WrappedBuffer::writeUInt16] buffer access out of bounds!");
		}
		value = value | 0;
		this.buffer[this._writePos + 1] = (value >> 8);
		this.buffer[this._writePos + 0] = value;
		this._writePos += 2;
	}

	public readUInt16LE(): number {
		if (this._limit < (this._readPos + 2)) {
			throw new BufferError("[WrappedBuffer::readUInt16] buffer access out of bounds!");
		}
		const out = (this.buffer[this._readPos + 1] << 8) | this.buffer[this._readPos + 0];
		this._readPos += 2;
		return out;
	}

	public writeInt24LE(value: number) {
		if (this._limit < (this._writePos + 3)) {
			throw new BufferError("[WrappedBuffer::writeInt24] buffer access out of bounds!");
		}
		value = value | 0;
		this.bufferS[this._writePos + 2] = (value >> 16);
		this.buffer[this._writePos + 1] = (value >> 8);
		this.buffer[this._writePos + 0] = value;
		this._writePos += 3;
	}

	public readInt24LE(): number {
		if (this._limit < (this._readPos + 3)) {
			throw new BufferError("[WrappedBuffer::readInt24] buffer access out of bounds!");
		}
		const out = (this.bufferS[this._readPos + 2] << 16) | (this.buffer[this._readPos + 1] << 8) | this.buffer[this._readPos + 0];
		this._readPos += 3;
		return out;
	}

	public writeUInt24LE(value: number) {
		if (this._limit < (this._writePos + 3)) {
			throw new BufferError("[WrappedBuffer::writeUInt24] buffer access out of bounds!");
		}
		value = value >>> 0;
		this.buffer[this._writePos + 2] = (value >> 16);
		this.buffer[this._writePos + 1] = (value >> 8);
		this.buffer[this._writePos + 0] = value;
		this._writePos += 3;
	}

	public readUInt24LE(): number {
		if (this._limit < (this._readPos + 3)) {
			throw new BufferError("[WrappedBuffer::readUInt24] buffer access out of bounds!");
		}
		const out = (this.buffer[this._readPos + 2] << 16) | (this.buffer[this._readPos + 1] << 8) | this.buffer[this._readPos + 0];
		this._readPos += 3;
		return out >>> 0;
	}

	public writeInt32LE(value: number) {
		if (this._limit < (this._writePos + 4)) {
			throw new BufferError("[WrappedBuffer::writeInt32] buffer access out of bounds!");
		}
		value = value | 0;
		this.buffer[this._writePos + 3] = (value >> 24);
		this.buffer[this._writePos + 2] = (value >> 16);
		this.buffer[this._writePos + 1] = (value >> 8);
		this.buffer[this._writePos + 0] = value;
		this._writePos += 4;
	}

	public readInt32LE(): number {
		if (this._limit < (this._readPos + 4)) {
			throw new BufferError("[WrappedBuffer::readInt32] buffer access out of bounds!");
		}
		const out = (this.buffer[this._readPos + 3] << 24) | (this.buffer[this._readPos + 2] << 16) | (this.buffer[this._readPos + 1] << 8) | this.buffer[this._readPos + 0];
		this._readPos += 4;
		return out;
	}

	public writeUInt32LE(value: number) {
		if (this._limit < (this._writePos + 4)) {
			throw new BufferError("[WrappedBuffer::writeInt32] buffer access out of bounds!");
		}
		value = value >>> 0;
		this.buffer[this._writePos + 3] = (value >> 24);
		this.buffer[this._writePos + 2] = (value >> 16);
		this.buffer[this._writePos + 1] = (value >> 8);
		this.buffer[this._writePos + 0] = value;
		this._writePos += 4;
	}

	public readUInt32LE(): number {
		if (this._limit < (this._readPos + 4)) {
			throw new BufferError("[WrappedBuffer::readInt32] buffer access out of bounds!");
		}
		const out = (this.buffer[this._readPos + 3] << 24) | (this.buffer[this._readPos + 2] << 16) | (this.buffer[this._readPos + 1] << 8) | this.buffer[this._readPos + 0];
		this._readPos += 4;
		return out >>> 0;
	}

	public writeFloat32LE(value: number) {
		if (this._limit < (this._writePos + 4)) {
			throw new BufferError("[WrappedBuffer::writeFloat32] buffer access out of bounds!");
		}
		internalFloatArray[0] = +value;
		this.buffer[this._writePos + 3] = (internalBytes[0] | 0);
		this.buffer[this._writePos + 2] = (internalBytes[1] | 0);
		this.buffer[this._writePos + 1] = (internalBytes[2] | 0);
		this.buffer[this._writePos + 0] = (internalBytes[3] | 0);
		this._writePos += 4;
	}

	public readFloat32LE(): number {
		if (this._limit < (this._readPos + 4)) {
			throw new BufferError("[WrappedBuffer::readFloat32] buffer access out of bounds!");
		}
		internalBytes[3] = this.buffer[this._readPos + 0];
		internalBytes[2] = this.buffer[this._readPos + 1];
		internalBytes[1] = this.buffer[this._readPos + 2];
		internalBytes[0] = this.buffer[this._readPos + 3];
		this._readPos += 4;
		return internalFloatArray[0];
	}

	public writeFloat64LE(value: number) {
		if (this._limit < (this._writePos + 8)) {
			throw new BufferError("[WrappedBuffer::writeFloat64] buffer access out of bounds!");
		}
		internalDoubleArray[0] = +value;
		this.buffer[this._writePos + 7] = (internalBytes[0] | 0);
		this.buffer[this._writePos + 6] = (internalBytes[1] | 0);
		this.buffer[this._writePos + 5] = (internalBytes[2] | 0);
		this.buffer[this._writePos + 4] = (internalBytes[3] | 0);
		this.buffer[this._writePos + 3] = (internalBytes[4] | 0);
		this.buffer[this._writePos + 2] = (internalBytes[5] | 0);
		this.buffer[this._writePos + 1] = (internalBytes[6] | 0);
		this.buffer[this._writePos + 0] = (internalBytes[7] | 0);
		this._writePos += 8;
	}

	public readFloat64LE(): number {
		if (this._limit < (this._readPos + 8)) {
			throw new BufferError("[WrappedBuffer::readFloat64] buffer access out of bounds!");
		}
		internalBytes[7] = this.buffer[this._readPos + 0];
		internalBytes[6] = this.buffer[this._readPos + 1];
		internalBytes[5] = this.buffer[this._readPos + 2];
		internalBytes[4] = this.buffer[this._readPos + 3];
		internalBytes[3] = this.buffer[this._readPos + 4];
		internalBytes[2] = this.buffer[this._readPos + 5];
		internalBytes[1] = this.buffer[this._readPos + 6];
		internalBytes[0] = this.buffer[this._readPos + 7];
		this._readPos += 8;
		return internalDoubleArray[0];
	}

}
