import { Utils } from "../utils/Utils";
import { BasicEvent } from "../utils/Event";
import { RegisterClass } from "./MetaDecorator";

export type ServiceType<T extends ServiceClass> = {
	readonly instance: T;
	dependencies: ServiceType<ServiceClass>[];
	createService(): T;
	readonly onServiceCreated?: BasicEvent<(service: T) => void>;
	singleton?: undefined | true;
	prototype: T;
	name: string;
};

export const Services: { [K: string]: ServiceType<ServiceClass> } = {};

export function Service<TClass extends ServiceClass>(name?: string) {
	return function <TTarget extends ServiceType<TClass>>(target: TTarget): TTarget | void {
		name = (name !== undefined) ? name : target.name;
		if (Services[name]) {
			throw new Error("Couldn't register " + target.name + " with name " + name + " as Service! Another service with that name is already registered!");
		}
		target.singleton = true;
		(target as any).instance = undefined;
		Services[name] = target;

		if (!target.createService) {
			throw new Error("Service doesn't have CreateService function on " + target.name + " with name " + name + "!");
		}

		let serviceCreatedEvent = new BasicEvent<(instance: TTarget) => void>();
		(target as any).onServiceCreated = serviceCreatedEvent;

		let classMeta = RegisterClass(target as any, true);

		serviceCreatedEvent.addCallback((instance) => {
			classMeta.attachSingleton(instance);
		});
	};
}

export abstract class ServiceClass {

	public static createService<T>(): T {
		const instance = new (this as any)();
		let clazz: ServiceType<ServiceClass> = Object.getPrototypeOf(instance).constructor;
		if (!clazz.hasOwnProperty("singleton")) {
			throw new Error("Can't attach instance of service! Service not registered correctly!");
		}

		// make sure there are no singletons already attached
		while (clazz.hasOwnProperty("singleton")) {
			if (clazz.hasOwnProperty("instance") && clazz.instance !== undefined) {
				throw new Error("Can't attach instance of " + Object.getPrototypeOf(instance).constructor.name + " service! " + clazz.name + " already attached!");
			}
			clazz = Object.getPrototypeOf(clazz);
		}

		clazz = Object.getPrototypeOf(instance).constructor;
		while (clazz.hasOwnProperty("singleton")) {
			(clazz as any).instance = instance;
			clazz = Object.getPrototypeOf(clazz);
		}
		Object.getPrototypeOf(instance).constructor.onServiceCreated.execute(instance);
		return instance;
	}

	public static get valid() {
		if ((this as any).instance) {
			return (this as any).instance.active;
		}
		return false;
	}

	public readonly onStartedEvent: BasicEvent<() => void> = new BasicEvent<any>();

	public readonly onStoppedEvent: BasicEvent<() => void> = new BasicEvent<any>();

	/**
	 * Only returns the instance when it's valid (enabled and active)
	 */
	public static getInstance() {
		if ((this as any).instance && (this as any).instance.active) {
			return (this as any).instance;
		}
		return undefined;
	}

	public static dependencies: ServiceType<ServiceClass>[] = [];

	public readonly active = false;

	protected setActive(active: boolean) {
		(this as any).active = active;
	}

	protected constructor() { }

	private __starting = false;

	public async start() {
		if (this.__starting === true || this.__stopping === true) {
			return false;
		}
		this.__starting = true;
		console.log("Starting service " + Object.getPrototypeOf(this).constructor.name);

		let ret;
		try {
			ret = await this.onStart();
		} catch (err) {
			console.log("Failed to start service " + Object.getPrototypeOf(this).constructor.name + ": force stopping", err);
			try {
				await this.onStop();
			} catch (err) {
				// ignore
			}
			this.__starting = false;
			this.__stopping = false;
			this.setActive(false);
			return false;
		}

		this.setActive(true);
		this.onStartedEvent.execute();
		this.__starting = false;
		console.log("Started service " + Object.getPrototypeOf(this).constructor.name);
		return ret;
	}

	private __stopping = false;
	public async stop() {
		if (this.__starting === true || this.__stopping === true) {
			return false;
		}
		this.__stopping = true;
		console.log("Stopping service " + Object.getPrototypeOf(this).constructor.name);
		this.setActive(false);
		let ret;
		try {
			ret = await this.onStop();
		} catch (err) {
			console.log("Error stopping service " + Object.getPrototypeOf(this).constructor.name, err);
			this.__stopping = false;
			return false;
		}
		this.onStoppedEvent.execute();
		this.__stopping = false;
		console.log("Stopped service " + Object.getPrototypeOf(this).constructor.name);
		return ret;
	}

	public async onStart() { return true; }

	public async onStop() { return true; }

	public async restart() {
		if (this.__starting === true || this.__stopping === true) {
			return false;
		}
		if (this.active && !await this.stop()) {
			return false;
		}
		if (this.active) {
			throw new Error("Service still active after stopping!");
		}
		await Utils.timeout(1);
		if (!await this.start()) {
			return false;
		}
		if (!this.active) {
			throw new Error("Service still not active after starting!");
		}
		return true;
	}
}
